import React from "react";
import Picker from "react-month-picker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "react-month-picker/css/month-picker.css";
import { Button } from "@mui/material";

// import '../css/MonthPicker.css';

class MonthBox extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			value: this.props.value || "N/A",
		};
		this._handleClick = this._handleClick.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value || "N/A",
		});
	}

	render() {
		return (
			<div>
				<div
					className="box"
					onClick={this._handleClick}
					style={{cursor: "pointer", width: "fit-content" }}
				>
					<label style={{ lineHeight: "1.5rem" }} id="box_month">
						<span style={{ display:"flex",alignItems:"center", fontSize: "15px", fontWeight: 500, border: "1px solid rgb(203 196 196)",borderRadius:"5px", paddingLeft: "4px" ,paddingTop:"1px",paddingBottom:"1px"}}>
							{this.props.value}
							<span style={{ marginTop: "5px" }}>
								<ArrowDropDownIcon />
							</span>
						</span>
					</label>
				</div>
			</div>
		);
	}

	_handleClick(e) {
		this.props.onClick && this.props.onClick(e);
	}
}

class MonthPicker extends React.Component {
	constructor(props, context) {
		super(props, context);

		let dateNow = new Date();

		this.state = {
			singleValue: { year: dateNow.getFullYear(), month: dateNow.getMonth()+1 },
			value: this.props.value,
		};

		this.pickAMonth = React.createRef();
	}
	render() {
		const pickerLang = {
			months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			from: "From",
			to: "To",
		};
		const { singleValue } = this.state;

		const makeText = (m) => {
			if (m && m.year && m.month) return pickerLang.months[m.month - 1] + ". " + m.year;
			return "Select Month";
		};

		return (
			<div className="edit">
				<Picker
					ref={this.pickAMonth}
					years={{
						min: {
							year: new Date().getFullYear() - 5,
							month: new Date().getMonth()+1,
						},
						max: {
							year: new Date().getFullYear(),
							month: new Date().getMonth()+1,
						},
					}}
					value={singleValue}
					lang={pickerLang.months}
					onChange={this.handleAMonthChange}
					onDismiss={this.handleAMonthDissmis}
				>
					<MonthBox value={makeText(singleValue)} onClick={this.handleClickMonthBox} />
				</Picker>
			</div>
		);
	}
	handleClickMonthBox = (e) => {
		this.pickAMonth.current.show();
	};

	handleAMonthChange = (year, month) => {
		let dateNow = new Date();
		if (year > dateNow.getFullYear() && month > dateNow.getMonth()) {
		} else {
			let current = new Date();
			current.setMonth(month - 1);
			let mon = current.toLocaleString("default", { month: "long" });
			this.props.changeMonth(mon, year, month);

			this.setState(
				{
					singleValue: { year, month },
				},
				() => this.pickAMonth.current.dismiss()
			);
		}
	};

	handleAMonthDissmis = (value) => {
		this.setState({
			singleValue: { year: value.year, month: value.month },
		});
	};
}

export default MonthPicker;
