import React, { useEffect, useState } from 'react';
import Sidemenu from '../Sidemenu';
import './index.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import Pagination from '@mui/material/Pagination';
import Snackbar from '@mui/material/Snackbar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '../Table';
import { makeRequest } from '../../_actions/fetch';
import { uri } from '../../config';
import Dialog from '../Dailog';
import { useMemo } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

/**
 * @typedef Order
 * @property {number} id
 * @property {number} wp_order_id
 * @property {number} user_id
 * @property {string} name
 * @property {string} email
 * @property {number} product_id
 * @property {Date | string} plan_start
 * @property {Date | string} plan_end
 * @property {string} currency
 * @property {number} amount
 * @property {number} amount_usd
 * @property {string} payment_made
 * @property {string} coupon
 * @property {number} base_service
 * @property {Date | string} created_at
 * @property {0 | 1} active
 * @property {string} product_name
 */

/**
 * @typedef Allotment
 * @property {number} id
 * @property {number} order_id
 * @property {number} wp_product_id
 * @property {number} user_id
 * @property {number} service_id
 * @property {number} [provider_id]
 * @property {number} sessions_remaining
 * @property {number} chats_remaining
 * @property {string} service_name
 * @property {string} name
 * @property {string} email
 * @property {string} pref
 */

/**
 * @typedef Provider
 * @property {number} id
 * @property {number} user_id
 * @property {number} crm_id
 * @property {string} category
 * @property {string} name
 * @property {string} phone
 * @property {string} email
 * @property {string} languages
 * @property {string} country
 * @property {string} gender
 * @property {string} specialization
 * @property {number} cases_done
 * @property {1 | 0} in_house
 * @property {Date | string} dob
 * @property {number} avg_rating
 * @property {string} religion
 * @property {number} billing_monthly
 * @property {number} billing_session
 * @property {number} billing_perc
 * @property {'fixed' | 'sharing' | null} billing_monthly
 * @property {number} billing_monthly
 * @property {number} b2c_complimentary
 * @property {number} b2b_fixed
 * @property {number} service_id
 * @property {1 | 0} availability
 * @property {string} picture
 * @property {string} other_details
 */

/** @typedef {Order & { allotments: Allotment[] }} OrderWAllotment */

function Orders() {
	/** @type {[OrderWAllotment[], React.Dispatch<React.SetStateAction<OrderWAllotment[]>>]} */
	const [data, setData] = useState([]);
	/** @type {[Provider[], React.Dispatch<React.SetStateAction<Provider[]>>]} */
	const [providers, setProviders] = useState([]);
	const [loading, setLoading] = useState(true);
	const [show, setShow] = useState(false);
	/** @type {[Allotment & { userName: string; userEmail: string }, React.Dispatch<React.SetStateAction<Allotment & { userName: string; userEmail: string }>>]} */
	const [dailogData, setDailogData] = useState({});
	const [keyword, setKeyword] = useState('');
	const [page, setPage] = useState(1);
	const [view, setView] = useState('all');
	const [snackShow, setSnackShow] = useState({
		open: false,
		message: '',
	});
	const [showPrefFilledOnly, setShowPrefFilledOnly] = useState(true);

	const [numOfRows, setNumOfRows] = useState(15);

	/**
	 * @param {{ userID: number, providerID: number, serviceID: number }} postData
	 */
	const confirmChange = async (postData) => {
		setShow(false);
		const result = await makeRequest(uri + '/dash/provider/change', 'POST', postData);
		const provider = providers.find((p) => p.user_id === postData.providerID);
		if (result.status) {
			setData((data) =>
				data.map((order) => {
					if (order.user_id !== postData.userID) {
						return order;
					}

					const isRelevant = order.allotments.some((al) => al.service_id === postData.serviceID);

					if (isRelevant) {
						return {
							...order,
							allotments: order.allotments.map((allotment) =>
								allotment.service_id === postData.serviceID
									? { ...allotment, provider_id: postData.providerID, name: provider?.name, email: provider?.email }
									: allotment,
							),
						};
					}

					return order;
				}),
			);
			setSnackShow({
				open: true,
				message: result.message,
			});
		} else {
			setSnackShow({
				open: true,
				message: result.message,
			});
		}
	};

	// mount hook
	useEffect(() => {
		(async function () {
			const result = await getAllOrders();
			if (result.status) {
				setLoading(false);

				setProviders(result.providers);
				setData(result.data);
			}
		})();
	}, []);

	/** @returns {Promise<{ providers: Provider[], data: OrderWAllotment[] }>} */
	async function getAllOrders() {
		const result = await makeRequest(uri + '/dash/orders/all');
		return result;
	}

	const assignedUnassigned = useMemo(() => {
		if (view === 'all') {
			return data;
		} else if (view === 'assign') {
			return data.filter((order) => order.allotments.every((al) => al.provider_id));
		} else {
			return data
				.map((order) => {
					const allotments = [];
					order.allotments.forEach((al) => !al.provider_id && (al.pref || !showPrefFilledOnly) && allotments.push(al));

					if (allotments.length) return { ...order, allotments };
					return false;
				})
				.filter((o) => o);
			return data.filter((order) => order.allotments.some((al) => !al.provider_id && (al.pref || !showPrefFilledOnly)));
		}
	}, [view, showPrefFilledOnly, data]);

	const filtered = useMemo(() => {
		const val = keyword.trim().toLowerCase();

		const int_val = parseInt(val);

		if (val.length < 3) {
			if (int_val) {
				const uFiltered = assignedUnassigned.filter((order) => order.user_id === int_val);

				if (uFiltered.length) return uFiltered;
			}
			return assignedUnassigned;
		}

		return assignedUnassigned.filter(
			(order) => order.name.toLowerCase().includes(val) || order.email.toLowerCase().includes(val) || order.wp_order_id === int_val || order.user_id === int_val,
		);
	}, [assignedUnassigned, keyword]);

	const [paginated, pageCount] = useMemo(() => {
		const start = (page - 1) * numOfRows;
		return [filtered.slice(start, start + numOfRows), Math.ceil(filtered.length / numOfRows)];
	}, [page, numOfRows, filtered]);

	const handleOpen = (data) => {
		setShow(true);
		setDailogData(data);
	};

	return (
		<Sidemenu role='admin'>
			<div className='order_wrapper'>
				<span className='heading'>Orders</span>
				<div className='filterBar'>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<div className=''>
							<FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
								<InputLabel id='demo-select-small'>Category</InputLabel>
								<Select labelId='demo-select-small' id='demo-select-small' value={view} label='View' onChange={(e) => setView(e.target.value)}>
									<MenuItem value='all'>All</MenuItem>
									<MenuItem value='assign'>Assigned</MenuItem>
									<MenuItem value='unassign'>Unassigned</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className='search'>
							<span style={{ fontSize: '17px' }}>Search&nbsp;&nbsp;</span>
							<OutlinedInput
								onChange={(e) => setKeyword(e.target.value)}
								placeholder='orders'
								size='small'
								style={{ maxWidth: '260px', width: '260px', background: 'white' }}
							/>
						</div>
						{view === 'unassign' && (
							<div style={{ marginLeft: 30 }}>
								<FormControlLabel
									control={<Checkbox checked={showPrefFilledOnly} size='small' onChange={(_, val) => setShowPrefFilledOnly(val)} />}
									label='With preference filled'
								/>
							</div>
						)}
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<div>{/* <Filter /> */}</div>
						<div className='perPage'>
							<FormControl sx={{ m: 1, minWidth: 60 }} size='small'>
								<InputLabel id='demo-select-small'>Rows</InputLabel>
								<Select labelId='demo-select-small' id='demo-select-small' value={numOfRows} label='Rows Count' onChange={(e) => setNumOfRows(e.target.value)}>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={25}>25</MenuItem>
									<MenuItem value={50}>50</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>

				{loading ? 'Loading...' : <Table data={paginated} handleOpen={handleOpen} />}
				<div style={{ display: 'flex', alignItems: 'center', padding: '30px 0px', justifyContent: 'center' }}>
					<Pagination count={pageCount} onChange={(e, v) => setPage(v)} />
				</div>
				<Dialog show={show} handleClose={() => setShow(false)} data={dailogData} providers={providers} confirmChange={confirmChange} />
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={snackShow.open}
					autoHideDuration={5000}
					onClose={() => setSnackShow({ open: false, message: '' })}
					message={snackShow.message}
				/>
			</div>
		</Sidemenu>
	);
}

export default Orders;
