import styles from './payments.module.css';
import Sidemenu from '../Sidemenu';
import { DataGrid } from '@mui/x-data-grid';
import { makeRequest, makeRequestNew } from '../../_actions/fetch';
import { uri } from '../../config';
import { useQuery } from 'react-query';
import { useRef, useState } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';

const columns = [
	{
		field: 'id',
		headerName: 'Id',
		width: 60,
		sortable: true,
		type: 'number',
		valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
	},
	{
		field: 'name',
		headerName: 'Name',
		width: 150,
		sortable: true,
		type: 'string',
		valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
	},
	{
		field: 'provider_id',
		headerName: 'Provider Id',
		width: 100,
		sortable: false,
		disableColumnMenu: true,
		type: 'number',
		valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
	},
	{
		field: 'payment_amount',
		headerName: 'amount',
		width: 100,
		sortable: false,
		disableColumnMenu: true,
		type: 'number',
		valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
	},
	{
		field: 'payment_date',
		headerName: 'DateTime',
		width: 100,
		type: 'date',
		sortable: false,
		valueFormatter: ({ value }) => new Date(value).toLocaleDateString('en-IN'),
	},
	{
		field: 'remarks',
		headerName: 'Remarks',
		width: 100,
		sortable: false,
		disableColumnMenu: true,
		type: 'number',
		valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
	},
	{
		field: 'time',
		headerName: ' EntryDate',
		width: 100,
		sortable: false,
		disableColumnMenu: true,
		type: 'date',
		valueFormatter: ({ value }) => new Date(value).toLocaleDateString('en-IN'),
	},
];

/**
 * @param {string} search
 * @param {number} page
 * @param {number} rows
 */
const getDataPaginated = async (search, page, rows) => {
	const response = await makeRequestNew(uri + `/billing/admin/payments/search?s=${search}&page=${page}&rows=${rows}`);
	const body = await response.json().catch(() => null);
	if (!response.ok) throw new Error(body?.message || 'something went wrong');
	return body;
};

const Payments = () => {
	const [numOfRows, setNumOfRows] = useState(15);
	const [searchText, setSearchText] = useState('');
	const tOutRef = useRef(null);
	const [page, setPage] = useState(0);
	const {
		data = [],
		isLoading,
		error,
	} = useQuery(['payments', searchText, page, numOfRows], () => {
		const trimSearch = searchText.trim().toLocaleLowerCase();
		if (trimSearch) {
			return getDataPaginated(trimSearch, 0, numOfRows);
		}
		return getDataPaginated('', page, numOfRows);
	});

	const handleSearch = (e) => {
		const text = e.target.value;
		tOutRef.current && clearTimeout(tOutRef.current);
		tOutRef.current = setTimeout(() => {
			setSearchText(text);
		}, 1000);
	};

	const handlePageChange = (val) => {
		if (data.length < numOfRows && page < val) return;
		setPage(val);
	};
	return (
		<Sidemenu>
			<div className={styles.provider_wrap}>
				<span className={styles.heading}>Payments</span>
				<div className={styles.filterBar}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<OutlinedInput onChange={handleSearch} placeholder='Search' size='small' style={{ maxWidth: '260px', width: '260px', background: 'white' }} />
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<div className='perPage'>
							<FormControl sx={{ m: 1, minWidth: 60 }} size='small'>
								<InputLabel id='demo-select-small'>Rows</InputLabel>
								<Select labelId='demo-select-small' id='demo-select-small' value={numOfRows} label='Rows Count' onChange={(e) => setNumOfRows(e.target.value)}>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={25}>25</MenuItem>
									<MenuItem value={50}>50</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
				<div style={{ height: '700px', width: '100%' }}>
					<DataGrid
						sx={{
							'.MuiTablePagination-displayedRows': {
								display: 'none',
							},
						}}
						rows={data}
						columns={columns}
						pageSize={numOfRows}
						loading={isLoading}
						onPageChange={handlePageChange}
						error={error}
						page={page}
						paginationMode='server'
						rowCount={Number.MAX_VALUE}
					/>
				</div>
			</div>
		</Sidemenu>
	);
};

export default Payments;
