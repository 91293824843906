import React from 'react';

const Page = () => {
	return (
		<div className='grid h-screen md:grid-cols-[max-content_auto]'>
			<div className='w-60 overflow-y-auto col-start-1 row-start-1 bg-blue-600'>
                <ul></ul>
            </div>
			<div className='row-start-1 sm:col-start-1 md:col-start-2 bg-blue-300'></div>
		</div>
	);
};

const Products = () => {
	return <Page></Page>;
};

export default Products;
