import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/** @type {React.FC<{ show: boolean; handleClose: Function; data: import('./Orders').Allotment & { userName: string; userEmail: string }; providers: import('./Orders').Provider[]; confirmChange: ({ userID: number, providerID: number, serviceID: number }) => any }>} */
const DialogCard = ({ show, handleClose, data, providers, confirmChange }) => {
	const [inputValue, setInputValue] = React.useState('');
	/** @type {[import('./Orders').Provider, React.Dispatch<import('./Orders').Provider>]} */
	const [value, setValue] = React.useState(null);

	const handleConfirm = () => {
		const postData = {
			provider: {
				id: value?.user_id || null,
				email: value?.email || undefined,
				name: value?.name || undefined,
				category: value?.category || undefined,
				other_details: value?.other_details || undefined,
				picture: value?.picture || undefined,
			},
			allotment: data,
		};
		confirmChange({ providerID: value?.user_id || null, serviceID: data.service_id, userID: data.user_id });
	};
	return (
		<div>
			{data && (
				<Dialog open={show} onClose={handleClose}>
					<DialogTitle>Provider</DialogTitle>
					<DialogContent>
						<DialogContentText>Select from the list and confirm.</DialogContentText>
						<br />
						<Autocomplete
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
							inputValue={inputValue}
							onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue);
							}}
							id='free-solo-demo'
							freeSolo
							options={providers}
							sx={{ width: 500 }}
							renderInput={(params) => <TextField {...params} label='Select Provider' />}
							getOptionLabel={(option) => option.name + ' - ' + option.email}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={() => handleConfirm()}>Confirm</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogCard;
