import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeRequestNew } from '../_actions/fetch';
import { uri } from '../config';
import { AUTH_FAIL, AUTH_SUCCESS } from '../_reducers/auth.reducer';
import { useQuery } from 'react-query';

/**
 * @returns {Promise<{ state: 'unauthenticated' } | { state: 'authenticated'; jwt: string; data: { id: number; name: string; email: string } }>}
 */
export const getSessionInfo = async () => {
	const res = await makeRequestNew(`${uri}/auth/info`);

	const body = await res.json();

	if (res.ok) {
		return body;
	}

	throw new Error(body?.message || 'Something went wrong');
};

export function useAuth() {
	const dispatch = useDispatch();

	const { data, isLoading, error } = useQuery(['AuthState'], getSessionInfo);

	useEffect(() => {
		if (!data) return;

		if (data.state === 'unauthenticated') {
			dispatch(AUTH_FAIL());
			return;
		}

		dispatch(AUTH_SUCCESS(data.data));
	}, [data]);

	return isLoading;
}
