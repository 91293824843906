import React, { useEffect, useState } from 'react';
import './provider.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import DialogComp from './Dailog';
import { getAllProviders } from '../../_actions/dashboard.actions';
import { connect } from 'react-redux';
import Sidemenu from '../Sidemenu';
import Filter from './Filter';

const Provider = ({ getAllProviders, provider: { providers, loading } }) => {
	const [page, setPage] = useState(1);
	const [showFull, setShowFull] = useState(false);
	const [selected, setSelected] = useState({});
	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [keyword, setKeyword] = useState('');

	const [filter, setFilter] = React.useState({
		specialization: [],
		country: '',
		category: '',
		availability: null,
	});

	const numOfRows = 12;
	const [count, setCount] = React.useState(Math.ceil(providers.length / numOfRows));

	const viewDetails = (data) => {
		setShowFull(true);
		setSelected(data);
	};
	const closeDetails = () => {
		setShowFull(false);
		setSelected({});
	};

	const handlePagination = (v) => {
		setPage(v);
		const arr = filtered.length > 0 ? filtered : providers;
		const arrayToUpdate = arr.slice((v - 1) * numOfRows, v * numOfRows);
		setData(arrayToUpdate);
	};

	const handleReset = () => {
		setFiltered([]);
		setFilter({
			specialization: [],
			country: '',
			category: '',
			availability: null,
		});
	};

	const handleFilter = (data) => {
		const { specialization, country, category, availability } = data;
		if (specialization.length === 0 && !country && !category && availability === null) {
			setFiltered([]);
			setData(providers.slice(0, numOfRows));
			setCount(Math.ceil(providers.length / numOfRows));
			return;
		}

		let final = providers.filter((provider) => {
			for (let [key, value] of Object.entries(filter)) {
				if (key === 'category' && value) {
					return provider.category && provider.category.replace(/[|, ]/g, '').toLowerCase() === value.replace(' ', '').toLowerCase();
				} else if (Array.isArray(value)) {
					if (value.length && value.some((spec) => provider[key]?.indexOf(spec) === -1)) {
						return false;
					}
				} else {
					if (value != null && typeof provider[key] === 'number' && provider?.[key] !== value) {
						return false;
					} else if (value && typeof provider[key] === 'string' && provider[key]?.toLowerCase() !== value?.toLowerCase()) {
						console.log({ cur: value?.toLowerCase(), prov: provider[key]?.toLowerCase() });
						return false;
					}
				}
			}
			return true;
		});

		final = [...new Set(final)];

		setFiltered(final);
		setData(final.slice(0, numOfRows));
		setCount(Math.ceil(final.length / numOfRows));
	};

	useEffect(() => {
		getAllProviders();
	}, [getAllProviders]);

	useEffect(() => {
		setData(providers.slice(0, numOfRows));
		setCount(Math.ceil(providers.length / numOfRows));
	}, [providers]);

	useEffect(() => {
		const final = [];
		if (keyword.length > 1) {
			providers.forEach((el) => {
				if (el.name.toLowerCase().indexOf(keyword) !== -1) {
					final.push(el);
				} else if (el.email.toLowerCase().indexOf(keyword) !== -1) {
					final.push(el);
				} else if (el.country.toLowerCase().indexOf(keyword) !== -1) {
					final.push(el);
				} else if (el.other_details?.degree.toLowerCase().indexOf(keyword) !== -1) {
					final.push(el);
				}
			});

			setData(final);
		} else {
			setData(providers.slice(0, numOfRows));
		}
	}, [keyword]);

	return (
		<Sidemenu role='admin'>
			<div className='provider_wrap'>
				<span className='heading'>Providers</span>
				<div className='filterBar'>
					<div>
						<div className='search'>
							<span style={{ fontSize: '17px' }}>Search&nbsp;&nbsp;</span>
							<OutlinedInput
								onChange={(e) => setKeyword(e.target.value)}
								placeholder='enter name, email or keyword'
								size='small'
								style={{ maxWidth: '260px', width: '260px', background: 'white' }}
							/>
						</div>
					</div>
					<div>
						<Filter setFilter={setFilter} handleFilter={handleFilter} handleReset={handleReset} filter={filter} />
					</div>
				</div>
				<div className='contentBody'>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container spacing={1}>
							{loading
								? 'Loading...'
								: data.length > 0 &&
								  data.map((el, i) => (
										<Grid item xs={12} sm={6} lg={3} xl={2} key={i}>
											<Paper style={{ padding: '15px 5px 10px 5px' }} elevation={3}>
												{/* {i % 3 == 0 ?
                                        <Chip variant="outlined" color="warning" size="small" label="New" style={{position: 'absolute', marginTop: '-10px'}} />
                                        : null} */}
												<div className='pavatar'>
													<div className={`available ${el.availability ? 'availableColor' : 'notAvailableColor'}`}></div>
													<Avatar alt={el.name} src={el.picture} sx={{ width: 80, height: 80 }} />
												</div>
												<div className='textDetails'>
													<h3>{el.name}</h3>
													<span className='textrow'>
														<span>Email</span>
														<span>{el.email}</span>
													</span>
													<span className='textrow'>
														<span>Category</span>
														<span>{el.category}</span>
													</span>
													<span className='textrow'>
														<span>Years of Experience</span>
														<span>{el.yoe}</span>
													</span>
													<span className='textrow'>
														<span>Country</span>
														<span>{el.country}</span>
													</span>
													<span className='textrow'>
														<span>Gender</span>
														<span>{el?.gender.toUpperCase()}</span>
													</span>
													<span className='textrow'>
														<span>Education</span>
														<span>{el.short_education}</span>
													</span>
													<span className='textrow'>
														<span>Languages Known</span>
														<span>{el.languages}</span>
													</span>
													<span className='textrow'>
														<span>Specialisation</span>
														<span style={{ height: '55px', overflow: 'hidden' }}>
															{el.specialization?.split(',').map((el) => (
																<Chip label={el} variant='outlined' size='small' className='specialisationChip' />
															))}
														</span>
													</span>
													<br />
													<Button size='small' onClick={() => viewDetails(el)}>
														View full profile
													</Button>
												</div>
											</Paper>
										</Grid>
								  ))}
						</Grid>
						<div style={{ margin: '30px 0px 0px 0px', display: 'flex', alignItems: 'center', padding: '5px' }}>
							<Pagination onChange={(e, v) => handlePagination(v)} page={page} count={count} style={{ margin: '0px auto' }} />
						</div>
					</Box>
					<DialogComp open={showFull} handleClose={() => closeDetails()} data={selected} />
				</div>
			</div>
		</Sidemenu>
	);
};

const mapStateToProps = (state) => ({
	provider: state.provider,
});

export default connect(mapStateToProps, { getAllProviders })(Provider);
