import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    users: []
}

export const userSlice=createSlice({
    name:"userSlice",
    initialState,
    reducers:{
        setUser:(state,action)=>{
            const users=action.payload
            state.users=users
        }
    }
})

export const {setNam}= userSlice.actions;

export default userSlice.reducer;