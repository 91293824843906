import { Button, OutlinedInput } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import { makeRequestNew } from '../../_actions/fetch';
import { uri } from '../../config';
import Sidemenu from '../Sidemenu';
import styles from './maliciousChat.module.css';
import RenderCellExpand from '../shared/RenderCellExpand';
import { useHistory } from 'react-router-dom';
import { useState, useMemo } from 'react';

const ChatViewCell = ({ row }) => {
	const { room_id } = row;
	const history = useHistory();
	const handleOnClick = async (e) => {
		history.push(`/chat/rooms/${room_id}`);
		return;
	};

	return <Button onClick={handleOnClick}>View</Button>;
};

const columns = [
	{
		field: 'id',
		headerName: 'Message Id',
		width: 100,
		sortable: true,
		type: 'string',
	},
	{
		field: 'provider',
		headerName: 'Provider',
		width: 200,
		type: 'string',
		valueFormatter: ({ value }) => `${value?.id} - ${value.name}`,
	},
	{
		field: 'room_name',
		headerName: 'Room Name',
		width: 120,
		sortable: false,
		disableColumnMenu: true,
		type: 'string',
	},
	{
		field: 'room_id',
		headerName: 'Room Id',
		width: 80,
		sortable: true,
		type: 'number',
	},
	{
		field: 'service_id',
		headerName: 'Service Id',
		width: 80,
		type: 'date',
		sortable: false,
	},
	{
		field: 'offending_phrase',
		headerName: 'Offending Phrase',
		width: 250,
		sortable: false,
		disableColumnMenu: true,
		type: 'string',
		renderCell: RenderCellExpand,
	},
	{
		field: 'created_at',
		headerName: 'Created At',
		width: 180,
		type: 'date',
		valueFormatter: ({ value }) => new Date(value).toLocaleDateString('en-IN'),
	},
	{
		field: 'Chat',
		header: 'Chat',
		width: 180,
		renderCell: ChatViewCell,
	},
];

/**
 * @param {string} search
 * @param {number} page
 * @param {number} rows
 *
 * @returns {{id:number,provider:{id:number,type:("provider"),name:string},room_name:string,service_id:number,offending_phrase:string,created_at:Date}[]}
 */
const getMaliciousMessages = async () => {
	const response = await makeRequestNew(uri + `/chat/admin/malicious`);
	const body = await response.json().catch(() => null);
	if (!response.ok) throw new Error(body?.message || 'something went wrong');
	return body;
};

const Malicious = () => {
	/** @type {string}*/
	const [search, setSearch] = useState('');
	const { data, isLoading, error } = useQuery(['malicious'], getMaliciousMessages);
	const filterDate = useMemo(() => {
		if (!data) return [];
		const trimSearchText = search.trim().toLocaleLowerCase();
		return data.filter(
			(data) =>
				data.provider.name.toLocaleLowerCase().includes(trimSearchText) || data.provider.id === Number(trimSearchText) || data.offending_phrase.includes(trimSearchText),
		);
	}, [data, search]);
	return (
		<Sidemenu>
			<div className={styles.provider_wrap}>
				<span className={styles.heading}>Malicious Chats</span>
				<div className={styles.filterBar}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<OutlinedInput
							placeholder='Search'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							size='small'
							style={{ maxWidth: '260px', width: '260px', background: 'white' }}
						/>
					</div>
				</div>
				<div style={{ height: '700px', width: '100%' }}>
					<DataGrid rows={filterDate} columns={columns} loading={isLoading} error={error} />
				</div>
			</div>
		</Sidemenu>
	);
};

export default Malicious;
