import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InputIcon from '@mui/icons-material/Input';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

function Row({ handleOpen, ...props }) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component='th' scope='row'>
					{row.created_at}
				</TableCell>
				<TableCell>{row.user_id}</TableCell>
				<TableCell>{row.wp_order_id}</TableCell>
				<TableCell>{row.name}</TableCell>
				<TableCell>{row.email}</TableCell>
				<TableCell>{row.plan_start}</TableCell>
				<TableCell>{row.plan_end}</TableCell>
				<TableCell>{row.product_id}</TableCell>
				<TableCell>{row.product_name}</TableCell>
				<TableCell>{row.coupon}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box sx={{ margin: 3 }}>
							<Typography variant='h6' gutterBottom component='div'>
								Allotments
							</Typography>
							<Table size='small' aria-label='purchases'>
								<TableHead>
									<TableRow>
										<TableCell>ServiceID</TableCell>
										<TableCell>Service Name</TableCell>
										<TableCell>ProviderID</TableCell>
										<TableCell>Provider Name</TableCell>
										<TableCell>Provider Email</TableCell>
										<TableCell style={{ width: '200' }}>Preference</TableCell>
										<TableCell>Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.allotments.length > 0 ? (
										row.allotments.map((allotment) => (
											<TableRow key={allotment.id}>
												<TableCell component='th' scope='row'>
													{allotment.service_id}
												</TableCell>
												<TableCell>{allotment.service_name}</TableCell>
												<TableCell>
													{allotment.provider_id || <div className='notAvail'>n/a</div>}
													{allotment.provider_id && (
														<Link to={`/provider/${allotment.provider_id}`} target='_blank' rel='noopener noreferrer'>
															<InputIcon
																style={{ fontSize: '17px', textAlign: 'center', margin: '0px 0px 0px 5px', color: '#0334AD' }}
															/>
														</Link>
													)}
												</TableCell>
												<TableCell>{allotment.name || <div className='notAvail'>Not Available</div>}</TableCell>
												<TableCell>{allotment.email || <div className='notAvail'>Not Available</div>}</TableCell>
												<TableCell>{allotment.pref || <div className='notAvail'>Not Available</div>}</TableCell>
												<TableCell>
													<Button size='small' onClick={() => handleOpen({ ...allotment, userName: row.name, userEmail: row.email })}>
														change
													</Button>
												</TableCell>
											</TableRow>
										))
									) : (
										<>No Allotment Record</>
									)}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

export default function CollapsibleTable({ data, handleOpen }) {
	return (
		<TableContainer component={Paper}>
			<Table aria-label='collapsible table'>
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>Date</TableCell>
						<TableCell>UserID</TableCell>
						<TableCell>OrderID</TableCell>
						<TableCell>Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Plan Start</TableCell>
						<TableCell>Plan End</TableCell>
						<TableCell>ProductID</TableCell>
						<TableCell>Product Name</TableCell>
						<TableCell>Coupon</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row, i) => (
						<Row key={i} row={row} handleOpen={handleOpen} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
