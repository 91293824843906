import { OutlinedInput } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { uri } from '../../config';
import { makeRequestNew } from '../../_actions/fetch';
import Sidemenu from '../Sidemenu';

const defaultImg = 'https://static.vecteezy.com/system/resources/previews/010/160/858/original/who-icon-sign-symbol-design-free-png.png';

/**
 * @typedef UserObject
 * @property {number} [id]
 * @property {string} name
 * @property {string} email
 * @property {string} [type]
 * @property {number} [corp]
 * @property {string} [img]
 * @property {string} [extras]
 */

/**
 * @param {string} query
 * @param {number} page
 * @param {number} count
 * @returns {Promise<{ users: UserObject[] }>}
 */
const getUsers = async (query, page, count = 18) => {
	const response = await makeRequestNew(`${uri}/dash//users/search?q=${query}&page=${page}&count=${count}`);

	const body = await response.json().catch(() => null);

	if (!response.ok) {
		throw new Error(body?.message || 'Request Failed');
	}

	return body;
};

const Badge = ({ color, title }) => {
	return (
		<div className={`capitalize inline text-white rounded-2xl px-2 text-sm py-0.5`} style={{ background: color }}>
			{title}
		</div>
	);
};

/** @type {React.FC<{ user: UserObject }>} */
const User = ({ user }) => {
	return (
		<div className='flex p-2 m-1 rounded-md bg-white sm:w-full md:w-[48%] lg:w-[32%] relative'>
			<div className='h-20 w-20 rounded-full overflow-hidden'>
				<img src={user.img || defaultImg} alt={`${user.name}#${user.id}`} className='h-full w-full object-cover' />
			</div>
			<div className='px-3 pt-4 flex-1'>
				<Link to={user.type === 'provider' ? `/provider/${user.id}` : `/users/${user.id}`}>
					<span className='text-blue-600 font-semibold mr-1 text-sm'>#{user.id}</span>
				</Link>
				<span className=''>{user.name}</span>
				<div>{user.email}</div>
				<div className='absolute top-0 right-0 p-1'>
					{user.type === 'provider' && <Badge title={user.type} color={'#2334ff'} />}
					{user.corp && <Badge title={'Corporate'} color={'#ff9900'} />}
				</div>
			</div>
		</div>
	);
};

const UserSkeleton = () => {
	return (
		<div className='flex p-2 m-1 rounded-md bg-white sm:w-full md:w-[48%] lg:w-[32%] relative animate-pulse'>
			<div className='h-20 w-20 rounded-full overflow-hidden bg-gray-300' />
			<div className='px-3 pt-4 flex-1'>
				<div className='inline-block font-semibold mr-1 w-8 bg-gray-300 h-4' />
				<span className='inline-block font-semibold mr-1 w-24 bg-gray-300 h-4' />
				<div className='font-semibold mr-1 bg-gray-300 h-4' />
			</div>
		</div>
	);
};

const Users = () => {
	const [query, setQuery] = useState('');
	const [page, setPage] = useState(0);
	/** @type {React.RefObject<NodeJS.Timeout>} */
	const tOutRef = useRef(null);

	const { isFetching, error, data, isPreviousData } = useQuery(['UserList', query, page], async () => getUsers(query, page), { keepPreviousData: true, staleTime: Infinity });

	/** @type  {React.ChangeEventHandler<HTMLInputElement>} */
	const handleChange = (e) => {
		const text = e.target.value.trim();

		tOutRef.current && clearTimeout(tOutRef.current);

		tOutRef.current = setTimeout(() => {
			setQuery(text);
			setPage(0);
		}, 500);
	};

	return (
		<Sidemenu role='admin'>
			<div className='py-2 px-4 bg-gray-100'>
				<span className='text-3xl'>Users</span>
				<div className='bg-slate-200 rounded-md px-6 py-4 mt-4'>
					<div className='inline-block mr-6'>
						<span>Search:&nbsp;&nbsp;</span>
						<OutlinedInput placeholder='email or name' size='small' className='w-64 bg-white' onChange={handleChange} />
					</div>
				</div>
			</div>
			<div className='px-6 py-4 bg-gray-100 flex flex-wrap'>
				{isFetching
					? Array(18)
							.fill(1)
							.map((_, i) => <UserSkeleton key={i} />)
					: data?.users && data.users.map((user) => <User key={user.id} user={user} />)}
			</div>
			<div className='flex justify-end py-3 px-10 bg-gray-100'>
				{!!page && (
					<div
						className='w-28 text-center bg-white mx-1 py-1 text-sky-600 rounded-md border-2 border-sky-600 cursor-pointer select-none'
						onClick={() => setPage((p) => p - 1)}
					>
						Prev
					</div>
				)}
				<div className='w-28 text-center bg-sky-600 mx-1 py-1 text-white rounded-md cursor-pointer select-none' onClick={() => setPage((p) => p + 1)}>
					Next
				</div>
			</div>
		</Sidemenu>
	);
};

export default Users;
