import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import styles from './providers.module.css';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useMemo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TextField from '@mui/material/TextField';
import { makeRequest, makeRequestNew } from '../../_actions/fetch';
import { uri } from '../../config';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';

/** @type {React.FC<{ row: import("./Providers").ProviderPricing; serviceList: import("./Providers").Service[] }>} */
const inputServiceSkeleton = {
	service_id: '',
	type: '',
	sharing: '',
	chat: '',
	video: '',
};

const addProviderPrice = async (data) => {
	try {
		const response = await makeRequest(`${uri}/billing/admin/prices`, 'POST', data);
		return response;
	} catch (err) {
		throw new Error(err);
	}
};

const deleteProviderPrice = async (id) => {
	try {
		await makeRequest(`${uri}/billing/admin/prices`, 'DELETE', { id });
	} catch (err) {
		throw new Error(err);
	}
};

const updateProviderPrice = async ({ id, updateBody }) => {
	try {
		await makeRequestNew(`${uri}/billing/admin/prices`, 'PATCH', {
			id,
			updateBody,
		});
	} catch (err) {
		throw new Error(err);
	}
};

/**
 * @typedef User
 * @property {number} id
 * @property {string} email
 * @property {string} name
 *
 * @param {User}
 * @returns {Promise<{token:string}>}
 */
const getUserJwt = async ({ id, email, name }) => {
	const response = await makeRequestNew(uri + '/auth/jwt', 'POST', {
		id,
		email,
		name,
	});
	const body = await response.json().catch(() => null);
	if (!response.ok) {
		throw new Error(body.message || 'something went wrong');
	}
	return body;
};

function Menus({ list, label, dataUpdateFunc, showType = [] }) {
	const [selectedLabel, setSelectedLabel] = useState(label);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openMenu = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuItemClick = (e) => {
		setAnchorEl(null);
		setSelectedLabel(dataUpdateFunc(e));
	};
	return (
		<>
			<Button id='fade-button' aria-controls={openMenu ? 'fade-menu' : undefined} aria-haspopup='true' aria-expanded={openMenu ? 'true' : undefined} onClick={handleClick}>
				{selectedLabel}
				<ArrowDropDownIcon style={{ marginBottom: '5px' }} />
			</Button>
			<Menu
				id='fade-menu'
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				anchorEl={anchorEl}
				open={openMenu}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{list.map((e, i) => (
					<MenuItem key={i} onClick={() => handleMenuItemClick(e)}>
						{showType.length !== 0 ? showType.map((z, i) => <span key={i}>{e[z]}&nbsp;</span>) : <span key={i}>{e}</span>}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

function NewProviderPriceRow({ data, provider_id, snackbarFunc, serviceList, filteredServiceList }) {
	const [editAddClick, setEditAddClick] = useState(false);
	const queryClient = useQueryClient();
	const [inputRow, setInputRow] = useState({});

	const handleServiceClick = (item) => {
		setInputRow({ ...inputRow, service_id: item.id });
		return item.service_name;
	};

	const handleTypeClick = (item) => {
		setInputRow({
			...inputRow,
			type: item,
		});
		setInputRow({
			...inputRow,
			type: item,
		});
		return item;
	};

	const { mutate: deleteGlobal, isLoading: deleteLoading } = useMutation('deleteProviderPrice', deleteProviderPrice, {
		onSuccess: () => {
			snackbarFunc('Price Deleted');
			queryClient.setQueryData('data', (allData) => ({
				...allData,
				rows: allData.rows.map((row) => {
					if (row.id === provider_id) {
						return {
							...row,
							price: row.price.filter((e) => e.id !== data.id),
						};
					}
					return row;
				}),
			}));
		},
	});

	const { mutate: addGlobal, isLoading: addLoading } = useMutation('addProviderPrice', addProviderPrice, {
		onSuccess: ({ insertId }) => {
			const index = serviceList.find((e) => e.id === inputRow.service_id);
			queryClient.setQueryData('data', (allData) => ({
				...allData,
				rows: allData.rows.map((currow) =>
					provider_id === currow.id
						? {
								...currow,
								price: [
									...currow.price,
									{
										id: insertId,
										service_id: inputRow.service_id,
										service_name: index.service_name,
										type: inputRow.type,
										chat: inputRow.chat,
										video: inputRow.video,
										sharing: inputRow.sharing,
									},
								],
						  }
						: currow,
				),
			}));
			snackbarFunc('Price Data Added');
			handleCancel();
		},
	});

	const { mutate: updateGlobal, isLoading: updateLoading } = useMutation('updateProviderPrice', updateProviderPrice, {
		onSuccess: () => {
			const index = serviceList.find((e) => e.id === inputRow.service_id);
			snackbarFunc('Price Data Updated');
			queryClient.setQueryData('data', (allData) => ({
				...allData,
				rows: allData.rows.map((currow) =>
					provider_id === currow.id
						? {
								...currow,
								price: currow.price.map((priceRow) =>
									priceRow.id === data.id
										? {
												id: data.id,
												service_name: index.service_name,
												...inputRow,
										  }
										: priceRow,
								),
						  }
						: currow,
				),
			}));
			setEditAddClick(false);
		},
	});

	const addButtonValidation = useMemo(() => {
		let check = false;
		if (inputRow.service_id === '' || inputRow.type === '') check = true;
		if (inputRow.type === 'sharing' && inputRow.sharing === '') check = true;
		if (inputRow.type === 'fixed' && (inputRow.chat === '' || inputRow.video === '')) check = true;
		return check;
	}, [inputRow]);

	const handleCancel = () => {
		setEditAddClick(false);
	};

	const [service, filterList] = useMemo(() => {
		if (data === undefined) return ['', filteredServiceList];
		const service = serviceList.find((e) => e.id === data.service_id);
		const filterList = [...filteredServiceList];
		filterList.push(service);
		return [service, filterList];
	}, [inputRow, filteredServiceList]);

	const onEditClick = () => {
		setEditAddClick(true);
		if (data === undefined) {
			setInputRow(inputServiceSkeleton);
		} else
			setInputRow({
				service_id: data.service_id,
				sharing: data.sharing,
				chat: data.chat,
				video: data.video,
				type: data.type,
			});
	};

	return (
		<TableRow>
			{!editAddClick ? (
				<>
					{data === undefined ? (
						<TableCell>
							<Button onClick={onEditClick}>+ Add</Button>
						</TableCell>
					) : (
						<>
							<TableCell>{data.service_id}</TableCell>
							<TableCell>{data.service_name}</TableCell>
							<TableCell>{data.type}</TableCell>
							<TableCell>{data.sharing || <div className={styles.notAvail}>n/a</div>}</TableCell>
							<TableCell>{data.video || <div className={styles.notAvail}>n/a</div>}</TableCell>
							<TableCell>{data.chat || <div className={styles.notAvail}>n/a</div>}</TableCell>
							<TableCell>
								<Button size='small' disabled={deleteLoading} onClick={onEditClick}>
									<EditIcon />
								</Button>
								<Button disabled={deleteLoading} onClick={() => deleteGlobal(data.id)} size='small'>
									<DeleteIcon />
								</Button>
							</TableCell>
						</>
					)}
				</>
			) : (
				<>
					<TableCell>{data === undefined ? <div style={{ marginBottom: '4px', fontSize: '1.5rem' }}>+</div> : <EditIcon />}</TableCell>
					<TableCell>
						<Menus
							list={filterList}
							label={data === undefined ? 'Services' : service.service_name}
							dataUpdateFunc={handleServiceClick}
							showType={['id', 'service_name']}
						/>
					</TableCell>
					<TableCell>
						<Menus list={['fixed', 'sharing']} label={data === undefined ? 'Type' : data.type} dataUpdateFunc={handleTypeClick} />
					</TableCell>
					<>
						<TableCell>
							<TextField
								type='number'
								InputProps={{ inputProps: { min: 0, max: 100 } }}
								id='outlined-basic'
								label='Session'
								variant='outlined'
								size='small'
								value={inputRow.video}
								onChange={(e) => setInputRow({ ...inputRow, video: e.target.value })}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</TableCell>
						<TableCell>
							<TextField
								type='number'
								InputProps={{ inputProps: { min: 0, max: 100 } }}
								id='outlined-basic'
								label='Chat'
								variant='outlined'
								size='small'
								value={inputRow.chat}
								onChange={(e) => setInputRow({ ...inputRow, chat: e.target.value })}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</TableCell>
					</>
					<TableCell>
						<TextField
							type='number'
							InputProps={{ inputProps: { min: 0, max: 100 } }}
							id='outlined-basic'
							label='Percentage'
							variant='outlined'
							size='small'
							value={inputRow.sharing}
							onChange={(e) => setInputRow({ ...inputRow, sharing: e.target.value })}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</TableCell>
					{data === undefined ? (
						<TableCell>
							<Button
								variant='contained'
								style={{ marginLeft: '1rem' }}
								onClick={() => addGlobal({ provider_id, ...inputRow })}
								disabled={addButtonValidation || addLoading}
							>
								Add
							</Button>
						</TableCell>
					) : (
						<TableCell>
							<Button
								variant='contained'
								style={{ marginLeft: '1rem' }}
								onClick={() => updateGlobal({ id: data.id, updateBody: inputRow })}
								disabled={addButtonValidation || updateLoading}
							>
								Update
							</Button>
						</TableCell>
					)}
					<TableCell>
						<Button variant='outlined' disabled={addLoading || updateLoading} onClick={handleCancel} color='error' style={{ marginLeft: '1rem' }}>
							Cancel
						</Button>
					</TableCell>
				</>
			)}
			{(updateLoading || addLoading || deleteLoading) && (
				<TableCell>
					<CircularProgress size={20} />
				</TableCell>
			)}
		</TableRow>
	);
}
const Row = ({ row, serviceList, snackbarFunc }) => {
	const [open, setOpen] = useState(false);

	const filteredServiceList = useMemo(() => {
		const list = serviceList.filter((e) => {
			if (row.price.find((z) => z.service_id === e.id)) return false;
			return true;
		});
		return list;
	}, [serviceList, row]);

	const { refetch, isError } = useQuery(['chatJwt'], () => getUserJwt({ id: row.id, email: row.email, name: row.name }), {
		enabled: false,
		refetchOnMount: false,
	});

	/**
	 *
	 * @param {React.FormEvent<HTMLAnchorElement>} e
	 */
	const handleChat = async (e) => {
		e.preventDefault();
		try {
			const { data } = await refetch();
			if (isError) throw new Error('something went wrong');
			const anchor = document.createElement('a');
			anchor.target="_blank"
			anchor.href = `http://localhost:3000/chat?token=${data?.token}`;
			anchor.click();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell>
					<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>
					<Link to={`/provider/${row.id}`} style={{ color: 'blue', textDecoration: 'underline' }}>
						{row.id}
					</Link>
				</TableCell>
				<TableCell>{row.category}</TableCell>
				<TableCell>{row.name}</TableCell>
				<TableCell>{row.email}</TableCell>
				<TableCell>{row.country}</TableCell>
				<TableCell>{row.gender}</TableCell>
				<TableCell>{row.dob}</TableCell>
				<TableCell>
					<a onClick={handleChat}>View</a>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box sx={{ margin: 3 }}>
							<Typography variant='h6' gutterBottom component='div'>
								Provider Prices
							</Typography>
							<Table size='small' aria-label='purchases'>
								<TableHead>
									<TableRow>
										<TableCell>ServiceID</TableCell>
										<TableCell>Service Name</TableCell>
										<TableCell>Type</TableCell>
										<TableCell>Sharing</TableCell>
										<TableCell>Sessions</TableCell>
										<TableCell>Chat</TableCell>
										<TableCell>Action</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.price.map((e) => (
										<NewProviderPriceRow
											data={e}
											provider_id={row.id}
											key={e.service_id}
											snackbarFunc={snackbarFunc}
											serviceList={serviceList}
											filteredServiceList={filteredServiceList}
										/>
									))}
									<NewProviderPriceRow provider_id={row.id} snackbarFunc={snackbarFunc} serviceList={serviceList} filteredServiceList={filteredServiceList} />
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
};

export default function CollapsibleTable({ data, serviceList, snackbarFunc }) {
	return (
		<TableContainer component={Paper}>
			<Table aria-label='collapsible table'>
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>id</TableCell>
						<TableCell>Category</TableCell>
						<TableCell>Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Country</TableCell>
						<TableCell>Gender</TableCell>
						<TableCell>Dob</TableCell>
						<TableCell>Chat</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row, i) => (
						<Row key={i} row={row} serviceList={serviceList} snackbarFunc={snackbarFunc} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
