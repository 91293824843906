import React, { useMemo, useRef, useState } from 'react';
import { makeRequestNew } from '../../_actions/fetch';
import { useQuery } from 'react-query';
import Sidemenu from '../Sidemenu';
import Styles from './ChatRooms.module.css';
import { uri } from '../../config';
import { Box, CircularProgress } from '@mui/material';
import { readableTime } from './helper';
import { getArrayMessage } from './helper';
import { fileImgLink } from './helper';

/**
 * @typedef Attach
 * @property {string} type
 * @property {string} url
 */

/**
 *
 * @typedef Message
 * @property {number} id
 * @property {Attach | string} attachment
 * @property {string} content
 * @property {boolean} visible
 * @property {string} created_at
 * @property {number} user_id
 *
 * @typedef RoomMembers
 * @property {number} id
 * @property {string} name
 * @property {string | null} img
 * @property {"provider" | "client" | null} type
 *
 *
 * @param {number} roomId
 * @returns {{messages:Message[],roomMembers:RoomMembers[]}}
 */
const getMessages = async (roomId) => {
	const response = await makeRequestNew(uri + `/chat/room/${roomId}`);
	const body = await response.json().catch((err) => null);
	if (!response.ok) {
		throw new Error('Something went wrong');
	}
	return body;
};

/**
 *
 * @param {{roomId:number; messageId:number}} param0
 * @returns {{messages:Message[]; roomId:number}}
 */
const getOldMessages = async ({ roomId, messageId }) => {
	const response = await makeRequestNew(uri + `/chat/${roomId}/${messageId}`);
	const body = await response.json().catch((err) => null);
	if (!response.ok) throw new Error('Something went wrong');
	return body;
};

/**
 * @type {React.FC<{ attachment: Attach }>}
 */
const AttachmentView = ({ attachment }) => {
	const { type, url } = JSON.parse(attachment);

	const fileName = useMemo(() => {
		if (!url) return '';
		const arr = url?.split('/');
		return arr[arr?.length - 1];
	}, [attachment?.url]);

	const preview = useMemo(() => {
		if (!type) return '';
		return type.includes('image') ? url : fileImgLink;
	}, [type]);

	return (
		<a href={url} target='_blank' download={url}>
			<img src={preview} style={{ maxheight: '100%', maxWidth: '100%' }} />
			<div style={{ wordBreak: 'break-all', fontSize: 10 }}>{fileName}</div>
		</a>
	);
};

/**
 * @type {React.FC<{message:Message,clientId:number,roomMembers:RoomMembers[], showAvatar:boolean }>}
 */
const Message = ({ message, clientId, roomMembers, showAvatar }) => {
	const { attachment, content, created_at, id, user_id, visible } = message;
	const member = roomMembers?.find((member) => member.id == user_id);

	if (user_id == 0) {
		return <div style={{ display: 'flex', justifyContent: 'center', margin: 5 }}>{getArrayMessage(content)}</div>;
	}

	if (user_id == clientId) {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-start', padding: 3, margin: 5 }}>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', position: 'relative', top: 13, height: 25, width: 25 }}>
					{showAvatar && (
						<>
							<img src={member?.img} style={{ height: 25, width: 25, borderRadius: 10 }} />
							<div style={{ fontSize: 10, textAlign: 'center' }}>{member?.name}</div>
						</>
					)}
				</div>
				<div style={{ borderRadius: 10, minWidth: '10%', maxWidth: '30%', padding: 5, marginLeft: 12, backgroundColor: 'wheat' }}>
					{visible ? (
						<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}>
							{attachment && <AttachmentView attachment={attachment} />}
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ fontSize: 16, wordBreak: 'break-word' }}>{content}</div>
								<div style={{ fontSize: 10 }}>{readableTime(created_at)}</div>
							</div>
						</div>
					) : (
						<div>this message is deleted</div>
					)}
				</div>
			</div>
		);
	} else {
		return (
			<div style={{ display: 'flex', justifyContent: 'flex-end', padding: 3, margin: 5 }}>
				<div style={{ borderRadius: 10, minWidth: '10%', maxWidth: '50%', padding: 5, marginRight: 12, backgroundColor: 'beige' }}>
					{visible ? (
						<div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
							{attachment && <AttachmentView attachment={attachment} />}
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
								<div style={{ fontSize: 18, wordBreak: 'break-word' }}>{content}</div>
								<div style={{ fontSize: 10 }}>{readableTime(created_at)}</div>
							</div>
						</div>
					) : (
						<div>this message is deleted</div>
					)}
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', position: 'relative', top: 13, height: 25, width: 25 }}>
					{showAvatar && (
						<>
							<img src={member?.img} style={{ height: 25, width: 25, borderRadius: 10 }} />
							<div style={{ fontSize: 10, textAlign: 'center' }}>{member?.name}</div>
						</>
					)}
				</div>
			</div>
		);
	}
};

/**
 * @type {React.FC<>}
 */
const RoomChat = ({ match }) => {
	/**
	 * @type {React.RefObject<HTMLDivElement>}
	 */
	const chatBodyRef = useRef(null);
	/**
	 * @type {[Message[],React.SetStateAction<Message[]>]}
	 */
	const [messages, setMessages] = useState([]);
	const oldChatAvail = useRef(true);
	/**
	 * @type {number}
	 */
	const { roomId } = match.params;

	const { isFetching, isError, data } = useQuery([roomId], () => getMessages(roomId), {
		onSuccess: ({ messages }) => {
			setMessages(messages);
		},
		refetchOnWindowFocus: false,
	});

	const {
		isFetching: prevLoading,
		isError: prevError,
		refetch,
	} = useQuery(
		'prevMessage',
		() => {
			const lastMessageId = messages.slice(-1)[0].id;
			return getOldMessages({ messageId: lastMessageId, roomId });
		},
		{
			enabled: false,
			onSuccess: (data) => {
				if (data?.messages.length == 0) {
					oldChatAvail.current = false;
					return;
				}
				setMessages([...messages, ...data?.messages]);
			},
			refetchOnWindowFocus: false,
		},
	);

	const handleScroll = () => {
		if (Math.abs(chatBodyRef.current.scrollTop) + chatBodyRef.current.clientHeight > chatBodyRef.current.scrollHeight - 500 && !prevLoading && oldChatAvail.current) {
			refetch();
		}
	};

	const client = useMemo(() => {
		if (!data) return null;
		else return data?.roomMembers.find((member) => member.type == 'client' || member.type == null);
	}, [data?.roomMembers]);

	return (
		<Sidemenu role={'admin'}>
			<div className={Styles.provider_wrap} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
				<div style={{ display: 'flex', padding: 10, width: '60%', backgroundColor: '#DCDCDC', alignItems: 'center' }}>
					<img
						src={client?.img || 'https://cdn.landesa.org/wp-content/uploads/default-user-image.png'}
						style={{ marginRight: 10, width: 40, height: 40, borderRadius: 50 }}
					/>
					<div>{client?.name} </div>
				</div>
				<Box
					ref={chatBodyRef}
					onScroll={handleScroll}
					sx={{
						height: 700,
						width: '60%',
						display: 'flex',
						flexDirection: 'column-reverse',
						...(isFetching || isError ? { alignItems: 'center', justifyContent: 'center' } : {}),
						overflow: 'auto',
						padding: 2,
						boxShadow: '#342f2f 0px 0px 5px;',
					}}
				>
					{!isError ? (
						<>
							{isFetching ? (
								<CircularProgress />
							) : (
								<>
									{messages.map((message, i) => (
										<Message
											message={message}
											clientId={client.id}
											key={message.id}
											roomMembers={data.roomMembers}
											showAvatar={messages[i + 1]?.user_id != message.user_id}
										/>
									))}
									{prevLoading && (
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											{' '}
											<CircularProgress size={30} />
										</div>
									)}
								</>
							)}
						</>
					) : (
						<div>Something went wrong</div>
					)}
				</Box>
			</div>
		</Sidemenu>
	);
};

export default RoomChat;
