import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BadgeIcon from '@mui/icons-material/Badge';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../_actions/user.actions';

const menuItems = [
	{ label: 'Home', slug: '/', Icon: HomeIcon },
	{
		label: 'Orders',
		Icon: AttachMoneyIcon,
		subItems: [
			{
				label: 'Business-2-Business',
				slug: '/orders/b2b',
			},
			{
				label: 'Business-2-Consumer',
				slug: '/orders/b2c',
			},
		],
	},
	{
		label: 'Providers',
		Icon: BadgeIcon,
		subItems: [
			{
				label: 'Pricing',
				slug: '/providers',
			},
			{
				label: 'Billing',
				slug: '/providers/billing',
			},
			{
				label: 'Sessions',
				slug: '/providers/sessions',
			},
			{
				label: 'Payments',
				slug: '/providers/payments',
			},
		],
	},
	{ label: 'Users', slug: '/users', Icon: PersonIcon },
	{
		label: 'Chat',
		Icon: QuestionAnswerIcon,
		subItems: [
			{
				label: 'Rooms',
				slug: '/chat/rooms',
			},
			{
				label: 'Malicious',
				slug: '/chat/malicious',
			},
		],
	},
];

function Sidemenu({ children }) {
	const [showDrawer, setshowDrawer] = useState(false);
	return (
		<div>
			<IconButton onClick={() => setshowDrawer(true)}>
				<MenuIcon />
			</IconButton>
			<Drawer open={showDrawer} onClose={() => setshowDrawer(false)}>
				<div style={{ paddingTop: 30, paddingBottom: 30 }}>
					{menuItems.map(({ label, slug, subItems, Icon }) => (
						<MenuList label={label} slug={slug} subItems={subItems} Icon={Icon} />
					))}

					<div className='logoutWrap'>
						<Button variant='outlined' onClick={(e) => logout()}>
							Logout
						</Button>
					</div>
				</div>
			</Drawer>
			<div>{children}</div>
		</div>
	);
}

/**
 * @typedef subItemType
 * @property {string} label
 * @property {string} slug
 *
 * @param {{label:string,slug?:string,subItems:subItemType[],Icon:()=>void}} param0
 * @returns
 */
const MenuList = ({ label, slug, subItems, Icon }) => {
	const [show, setShow] = useState(false);

	if (subItems) {
		return (
			<>
				<ListItemButton onClick={() => setShow((prev) => !prev)}>
					<ListItemIcon>
						<Icon />
					</ListItemIcon>
					<ListItemText primary={label} />
					{show ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={show} timeout={'auto'}>
					<List>
						{subItems.map(({ label, slug }) => (
							<Link to={slug}>
								<ListItemButton sx={{ pl: 4 }}>
									<ListItemText primary={label} />
								</ListItemButton>
							</Link>
						))}
					</List>
				</Collapse>
			</>
		);
	} else {
		return (
			<Link to={slug}>
				<ListItemButton>
					<ListItemIcon>
						<Icon />
					</ListItemIcon>
					<ListItemText primary={label} />
				</ListItemButton>
			</Link>
		);
	}
};

export default connect(null, { logout })(Sidemenu);
