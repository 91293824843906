import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    providers: [],
    loading: true
}

export const providerSlice=createSlice({
    name:"providerSlice",
    initialState,
    reducers:{
        setProviders:(state,action)=>{
            const providers=action.payload
            state.providers=providers
        }
    }
})

export const {setProviders}=providerSlice.actions;

export default providerSlice.reducer