import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
	const { isAuthenticated, loading, type } = useSelector((state) => state.auth);

	if (!isAuthenticated && !loading) {
		return <Route render={() => <Redirect to={'/login'} />} />;
	}

	if (isAuthenticated && roles && roles.indexOf(type) === -1) {
		return <Route render={() => <Redirect to={'/login'} />} />;
	}

	return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
