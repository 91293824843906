import authSlice from './auth.reducer';
import corpSlice from './corp.reducer';
import userSlice from './user.reducer';
import providerSlice from './provider.reducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
        provider:providerSlice,
        auth:authSlice,
        corp:corpSlice,
        user:userSlice
})

export default rootReducer;