import { LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT } from '../_reducers/auth.reducer';
import * as config from '../config';
import { makeRequestNew } from './fetch';

/**
 * @param {string} username
 * @param {string} password
 * @returns {Promise<true>}
 */
export const authenticate = async (username, password) => {
	const res = await makeRequestNew(`${config.uri}/auth/login`, 'POST', { username, password });

	if (res.ok) {
		return true;
	}

	const body = await res.json().catch(() => null);

	throw new Error(body?.message || 'Something went wrong');
};

export const login = (email, password) => async (dispatch) => {
	try {
		const res = await authenticate(email, password);

		dispatch(LOGIN_SUCCESS({ token: 'xxxxxxxxxx', type: 'superuser' }));
	} catch (err) {
		dispatch(LOGIN_FAILED({ message: err.message }));
	}
};

export const logout = () => async (dispatch) => {
	dispatch(LOGOUT());
};
