import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import React, { Component } from 'react';
import './login.css';
import { connect } from 'react-redux';
import { login as loginAction } from '../../_actions/user.actions';
import { Redirect } from 'react-router-dom';

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			remember: false,
			email: '',
			password: '',
		};
		this.remember = localStorage.getItem('remember');
		if (this.remember) {
			this.remember = JSON.parse(this.remember);
			this.state.remember = true;
			this.state.email = this.remember.email;
			this.state.password = this.remember.password;
		}
	}
	listener = (e) => {
		if (e.code === 'Enter' || e.code === 'NumpadEnter') {
			e.preventDefault();
			this.handleSubmit();
		}
	};
	componentDidMount() {
		document.addEventListener('keydown', this.listener);
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.listener);
	}
	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handleChecked = (v) => {
		this.setState({
			remember: v,
		});
	};
	handleSubmit = async () => {
		let { email, password, remember } = this.state;
		if (remember) {
			localStorage.setItem('remember', JSON.stringify({ email, password }));
		} else {
			localStorage.removeItem('remember');
		}
		await this.props.loginAction(email, password);
	};

	render() {
		if (this.props.auth.isAuthenticated) {
			return <Redirect to='/' />;
		}

		return (
			<div className='login_wrapper'>
				<div className='centered_div'>
					{this.props.auth.error}
					<Paper elevation={3} style={{ padding: '30px 50px' }}>
						<img src='https://mantracare.org/wp-content/uploads/2021/07/MC-Logo_new-x497-300x65.png' style={{ height: '35px', marginBottom: '50px' }} alt='logo' />
						{/* <span style={{fontSize: '25px', marginBottom: '50px', display: 'block'}}>Login</span> */}
						<TextField
							required
							id='outlined-required'
							label='Email or username'
							placeholder='Enter username or email'
							fullWidth
							size='small'
							autoComplete='off'
							name='email'
							value={this.state.email}
							onChange={(e) => this.onChange(e)}
						/>
						<br />
						<br />
						<TextField
							required
							id='outlined-required'
							label='Password'
							placeholder='Enter password'
							fullWidth
							size='small'
							autoComplete='off'
							name='password'
							value={this.state.password}
							onChange={(e) => this.onChange(e)}
							type='password'
						/>
						<br />
						<FormGroup>
							<FormControlLabel control={<Checkbox checked={this.state.remember} onChange={(e) => this.handleChecked(e.target.checked)} />} label='Remember me' />
						</FormGroup>
						<br />
						<br />
						<Button variant='contained' fullWidth onClick={() => this.handleSubmit()}>
							Signin
						</Button>
					</Paper>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { loginAction })(LoginPage);
