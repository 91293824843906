import CachedIcon from '@mui/icons-material/Cached';
import SaveIcon from '@mui/icons-material/Save';
import { FormControlLabel, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import { DataGrid } from '@mui/x-data-grid';
import * as jsonexport from 'jsonexport/dist';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Picker from '../Billing/MonthPicker';
import Sidemenu from '../Sidemenu';
import AddSessionDialog from './AddSessionDialog';
import Service, { DataKeys, columns, extraColumns, presentDate } from './helper';
import styles from './sessions.module.css';
/**
 *
 * @param {*} params
 * @type {React.FC<Params>}
 * @returns {React.ReactElement}
 */
const CostUpdateButton = ({ data, handleCostUpdate }) => {
	return (
		<IconButton
			aria-label='save'
			onClick={() =>
				handleCostUpdate({
					id: data.id,
					updatedCost: data.row.adjusted_cost,
					type: data.row.session_type,
				})
			}
		>
			<SaveIcon />
		</IconButton>
	);
};

/**
 * @typedef {object} Props
 * @property {boolean} open
 * @property {function} handleClose
 * @property {object} proUsData
 * @property {function} handleSnackBarShow
 * @property {function} addnewSessionLocal
 * @type {React.FC<Props>}
 * @returns {React.ReactElement}
 */

/**
 * main component of sessions page
 *  @typedef {object} Props
 * @type {React.FC<Props>}
 * @returns {React.ReactElement}
 */
function Sessions() {
	const [status, setStatus] = useState('all');
	const [search, setSearch] = useState('');
	const [numOfRows, setNumOfRows] = useState(15);
	const [date, setDate] = useState(presentDate);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: '',
	});
	const [sortType, setSortType] = useState('auto');

	const {
		data,
		refetch: refetchTableData,
		isFetching,
		error,
	} = useQuery(
		[date.toISOString(), sortType],
		async () => {
			const { chat, video } = await Service.getData(date, sortType);

			let finalData = {};

			DataKeys.forEach((e) => {
				const arr = [];
				if (chat[e] !== undefined) arr.push(...chat[e]);
				if (video[e] !== undefined) arr.push(...video[e]);
				if (arr.length > 0)
					finalData[e] = [
						...arr.sort((a, b) => (sortType == 'auto' ? new Date(b.session_date) - new Date(a.session_date) : new Date(b.entry_date) - new Date(a.entry_date))),
					];
			});
			return finalData;
		},
		{
			refetchOnWindowFocus: false,

			refetchOnReconnect: false,
		},
	);

	const {
		data: proUsData = {
			providersWithId: [],
			usersWithId: [],
			services: [],
		},
		refetch,
		isFetched,
		isFetching: proUsDataIsFetching,
		error: proUsDataError,
	} = useQuery('providerUserData', Service.getInputData, {
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		enabled: false,
	});

	const { mutate: updateCostMutate, isLoading: updateCostLoading } = useMutation('updateCostData', Service.updateCost, {
		onError: (body) => {
			handleSnackBarShow(body.message);
		},
		onSuccess: (body) => {
			handleSnackBarShow(body.message);
			refetch();
		},
	});

	const { mutate: approveMutate, isLoading: approveVideoLoading } = useMutation('approveSession', Service.approveSession, {
		onError: (body) => {
			handleSnackBarShow(body.message);
		},
		onSuccess: (body) => {
			handleSnackBarShow(body.message);
			refetchTableData();
		},
	});

	const { mutate: rejectMutate, isLoading: rejectLoading } = useMutation('rejectSession', Service.rejectSession, {
		onError: (body) => {
			handleSnackBarShow(body.message);
		},
		onSuccess: (body) => {
			handleSnackBarShow(body.message);

			refetchTableData();
		},
	});

	const { mutate: recalCostMutate, isLoading: recalCostLoading } = useMutation('recalculateCost', Service.recalCost, {
		onError: (body) => {
			handleSnackBarShow(body.message);
			refetchTableData();
		},
		onSuccess: (body) => {
			handleSnackBarShow(body.message);
			refetchTableData();
		},
	});

	const handleDialogOpen = () => {
		!isFetched && refetch();
		setDialogOpen(true);
	};

	const handleClose = useCallback(() => {
		setDialogOpen(false);
	}, []);

	const changeMonth = (_, year, num) => {
		setDate(new Date(year, num - 1));
	};

	const downloadCsv = () => {
		jsonexport(filterData, function (err, csv) {
			const file = new Blob([csv], { type: 'text/csv' });

			const element = document.createElement('a');

			element.href = URL.createObjectURL(file);

			element.download = `SessionData_${status}_${date}.csv`;

			document.body.appendChild(element);

			element.click();
		});
	};

	const handleSnackBarShow = useCallback((message) => {
		setSnackbar({
			open: true,
			message,
		});
	}, []);

	const addColumn = useMemo(() => {
		let columns = [];

		if (status === 'all') {
			columns.push(...extraColumns['all']);
		}

		if (status === 'valid') {
			columns.push(
				...extraColumns['valid'],
				{
					field: 'actions',
					headerName: 'Action',
					width: 60,
					sortable: false,
					disableColumnMenu: true,
					renderCell: (e) => <CostUpdateButton handleCostUpdate={handleCostUpdate} data={e} />,
				},
				{
					field: 'recal_cost',
					headerName: 'Recal Cost',
					sortable: false,
					disableColumnMenu: true,
					renderCell: (e) => (
						<IconButton
							aria-label='save'
							onClick={() =>
								recalCostMutate({
									id: e.id,
									type: e.row.session_type,
								})
							}
						>
							<CachedIcon />
						</IconButton>
					),
				},
			);
		}

		if (status === 'pending') {
			columns.push(
				{
					field: 'approve',
					headerName: '',
					sortable: false,
					disableColumnMenu: true,
					renderCell: (e) => (
						<Button
							variant='contained'
							onClick={() =>
								handleAproval({
									id: e.id,
									type: e.row.session_type,
									status: e.row.status,
								})
							}
						>
							Approve
						</Button>
					),
				},
				{
					field: 'reject',
					headerName: '',
					sortable: false,
					disableColumnMenu: true,
					renderCell: (e) => (
						<Button
							variant='contained'
							color='error'
							onClick={() =>
								handleReject({
									id: e.id,
									type: e.row.session_type,
									status: e.row.status,
								})
							}
						>
							Reject
						</Button>
					),
				},
			);
		}

		if (status === 'disputed') {
			columns.push(
				{
					field: 'approveDisputed',
					headerName: '',
					sortable: false,
					disableColumnMenu: true,
					renderCell: (e) => (
						<Button
							variant='contained'
							onClick={() =>
								handleAproval({
									id: e.id,
									type: e.row.session_type,
									status: e.row.status,
								})
							}
						>
							Approve
						</Button>
					),
				},
				{
					field: 'rejectDisputed',
					headerName: '',
					sortable: false,
					disableColumnMenu: true,
					renderCell: (e) => (
						<Button
							variant='outlined'
							color='error'
							onClick={() =>
								handleReject({
									id: e.id,
									type: e.row.session_type,
									status: e.row.status,
								})
							}
						>
							Reject
						</Button>
					),
				},
			);
		}
		return columns;
	}, [status]);

	const assignedData = useMemo(() => {
		if (data === undefined) return [];

		if (data[status] === undefined) setStatus('all');

		if (status === 'all') {
			let allData = [];

			Object.keys(data).forEach((e) => {
				allData = [...allData, ...data[e]];
			});

			return allData.sort((a, b) => new Date(b.requested) - new Date(a.requested));
		}

		return data[status] || [];
	}, [status, data]);

	const [filterData, total] = useMemo(() => {
		if (!assignedData.length) return [[], 0];

		let total = 0;
		const stripSearch = search.trim().toLocaleLowerCase();

		if (stripSearch === '') return [assignedData, assignedData.reduce((acc, cur) => (acc += cur.adjusted_cost || cur.cost), 0)];

		const final = assignedData.filter((e) => {
			const status =
				e.session_type === stripSearch ||
				e.order_id === stripSearch ||
				String(e.id).includes(stripSearch) ||
				String(e.provider_id) === stripSearch ||
				String(e.user_id).includes(stripSearch) ||
				e.user_name.toLocaleLowerCase().trim().includes(stripSearch) ||
				e.provider_name.toLocaleLowerCase().trim().includes(stripSearch);

			if (status) {
				total += e.adjusted_cost || e.cost;
			}

			return status;
		});

		return [final, total.toFixed(2)];
	}, [assignedData, search]);

	const handleCostUpdate = ({ id, updatedCost, type }) => {
		if (updatedCost === null || updatedCost === undefined) {
			handleSnackBarShow('Entered adjusted_cost is null or undefined');
			return;
		}

		updateCostMutate({ id, updatedCost, type });
	};

	const handleAproval = useCallback(({ id, type, status }) => {
		if (id === null || status === null) {
			handleSnackBarShow('Something went wrong');
			return;
		}

		approveMutate({ id, type, status });
	}, []);

	const handleReject = useCallback(({ id, type, status }) => {
		if (id === null || status === null) {
			handleSnackBarShow('Something went wrong');
			return;
		}

		rejectMutate({ id, type, status });
	}, []);

	if (error) return <div>Error Occured</div>;

	return (
		<Sidemenu role='admin'>
			<div className={styles.provider_wrap}>
				<span className={styles.heading}>Sessions</span>
				<div className={styles.filterBar}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<div className=''>
							<FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
								<InputLabel id='demo-select-small'>Status</InputLabel>
								<Select
									labelId='demo-select-small'
									id='demo-select-small'
									value={status}
									label='View'
									style={{ textTransform: 'capitalize' }}
									onChange={(e) => setStatus(e.target.value)}
								>
									<MenuItem value={'all'} key={'all'} style={{ textTransform: 'capitalize' }}>
										all
									</MenuItem>
									{data &&
										Object.keys(data).map((key) => (
											<MenuItem value={key} key={key} style={{ textTransform: 'capitalize' }}>
												{key}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</div>
						<FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
							<InputLabel id='demo-select-small'>Sort</InputLabel>
							<Select labelId='demo-select-small' id='demo-select-small' label={"Sort"} value={sortType} onChange={(e) => setSortType(e.target.value)}>
								<MenuItem value={'auto'} key={'auto'}>
									by Session_date
								</MenuItem>
								<MenuItem value={'manual'} key={'manual'}>
									by Entry_date
								</MenuItem>
							</Select>
						</FormControl>
						<OutlinedInput
							onChange={(e) => setSearch(e.target.value)}
							placeholder='Search'
							size='small'
							value={search}
							style={{ maxWidth: '260px', width: '260px', background: 'white' }}
						/>
						<div style={{ marginLeft: '10px' }}>
							<Picker date={date} changeMonth={changeMonth} />
						</div>
						<div style={{ marginLeft: '10px' }}>
							<Button variant='contained' disableElevation onClick={handleDialogOpen}>
								Add Session
							</Button>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<div className='perPage'>
							<FormControl sx={{ m: 1, minWidth: 60 }} size='small'>
								<InputLabel id='demo-select-small'>Rows</InputLabel>
								<Select labelId='demo-select-small' id='demo-select-small' value={numOfRows} label='Rows Count' onChange={(e) => setNumOfRows(e.target.value)}>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={25}>25</MenuItem>
									<MenuItem value={50}>50</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
				<div
					style={{
						marginRight: '25px',
						marginBottom: '10px',
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<div className='mr-auto'>
						<span className='text-zinc-600'>Total:&nbsp;</span> {total}
					</div>
					{(isFetching || updateCostLoading || approveVideoLoading || rejectLoading || recalCostLoading) && <CircularProgress style={{ marginRight: '10px' }} />}
					<Button onClick={downloadCsv} disabled={filterData.length === 0} variant='contained' disableElevation>
						Download
					</Button>
				</div>
				<div style={{ height: '700px', width: '100%' }}>
					<DataGrid
						rows={filterData}
						columns={[...columns, ...addColumn]}
						pageSize={numOfRows}
						onPageSizeChange={(newPageSize) => setNumOfRows(newPageSize)}
						disableSelectionOnClick
						pagination
						error={error}
					/>
				</div>
			</div>
			<AddSessionDialog
				open={dialogOpen}
				handleClose={handleClose}
				proUsData={proUsData}
				isFetching={proUsDataIsFetching}
				error={proUsDataError}
				handleSnackBarShow={handleSnackBarShow}
				addnewSessionLocal={refetchTableData}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={snackbar.open}
				autoHideDuration={5000}
				onClose={() => setSnackbar({ open: false, message: '' })}
				message={snackbar.message}
			/>
		</Sidemenu>
	);
}

export default Sessions;
