import React, { useEffect, useState } from 'react';
import './provider.css';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Sidemenu from '../Sidemenu';
import { useParams } from 'react-router-dom';
import { makeRequest } from '../../_actions/fetch';
import { uri } from '../../config';

const SingleProvider = (props) => {
	const { id } = useParams();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async function () {
			if (id) {
				const data = await getProvider(id);
				setData(data.result);
				setLoading(false);
			}
		})();
	}, []);

	async function getProvider(user_id) {
		const result = await makeRequest(uri + `/dash/provider/${user_id}`);
		return result;
	}

	return (
		<Sidemenu role='admin'>
			<div className='provider_wrap'>
				<span className='heading'>Provider</span>

				<div className='contentBody'>
					<Box sx={{ flexGrow: 1 }} style={{ margin: '0px auto', width: '50%' }}>
						{loading
							? 'Loading...'
							: data &&
							  Object.keys(data).length > 0 && (
									<Paper style={{ padding: '15px' }} elevation={3}>
										<div className='pavatar'>
											<Avatar alt={data.name} src={data.picture} sx={{ width: 130, height: 130 }} />
										</div>
										<div className='textDetails'>
											<h3 className='text-lg'>{data.name}</h3>
											<span className='textrow'>
												<span>Status</span>
												<span>
													{data.availability ? (
														<span style={{ color: 'green' }}>Available</span>
													) : (
														<span style={{ color: 'orange' }}>Not Available</span>
													)}
												</span>
											</span>
											<span className='textrow'>
												<span>Email</span>
												<span>{data.email}</span>
											</span>
											<span className='textrow'>
												<span>Category</span>
												<span>{data.category}</span>
											</span>
											<span className='textrow'>
												<span>Years of Experience</span>
												<span>{data.yoe}</span>
											</span>
											<span className='textrow'>
												<span>Country</span>
												<span>{data.country}</span>
											</span>
											<span className='textrow'>
												<span>Gender</span>
												<span>{data?.gender.toUpperCase()}</span>
											</span>
											<span className='textrow'>
												<span>Education</span>
												<span>{data.short_education}</span>
											</span>
											<span className='textrow'>
												<span>Languages Known</span>
												<span>{data.languages}</span>
											</span>
											<span className='textrow'>
												<span>Specialisation</span>
												<span style={{ height: '55px', overflow: 'hidden' }}>
													{data.specialization?.split(',').map((el) => (
														<Chip label={el} variant='outlined' size='small' className='specialisationChip' />
													))}
												</span>
											</span>
											<span className='textrow'>
												<span>Profile Description</span>
												<span>{data.other_details.profile_desc}</span>
											</span>
											<br />
										</div>
									</Paper>
							  )}
					</Box>
				</div>
			</div>
		</Sidemenu>
	);
};

export default SingleProvider;
