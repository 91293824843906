import { uri } from "../config"
import { GET_PROVIDERS, GET_STATS_ANALYSIS, GET_STATS_LIST } from "../constans";
import { makeRequest } from "./fetch"

export const getAllProviders = () => async (dispatch) => {
    try{

        const result = await makeRequest(uri + '/dash/providers/all');
        dispatch({ type: GET_PROVIDERS, payload: result.data })

    }catch(err){
        console.log(err)
    }
}

export const getCorpStats = () => async (dispatch) => {
    try{

        const result = await makeRequest(uri + '/dash/stats');
        if(result.status){
            // dispatch({ type: GET_STATS_ANALYSIS, payload: result.analysis })
            dispatch({ type: GET_STATS_LIST, payload: result.data })
        }

    }catch(err){
        console.log(err)
    }
}

export const getCorpSummary = () => async (dispatch) => {
    try{

        const result = await makeRequest(uri + '/dash/summary');
        if(result.status){
            dispatch({ type: GET_STATS_ANALYSIS, payload: result.data })
        }

    }catch(err){
        console.log(err)
    }
}
