import styles from "./providers.module.css";
import { CircularProgress, OutlinedInput } from "@mui/material";
import Sidemenu from "../Sidemenu";
import { useState } from "react";
import Table from "./Table";
import { makeRequest } from "../../_actions/fetch";
import { uri } from "../../config";
import { useQuery } from "react-query";
import { useMemo } from "react";
import Snackbar from "@mui/material/Snackbar";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

/**
 * @typedef FixedPricing
 * @property {number} service_id
 * @property {string} service_name
 * @property {'fixed'} type
 * @property {number} video
 * @property {number} chat
 *
 * @typedef SharedPricing
 * @property {number} service_id
 * @property {string} service_name
 * @property {'sharing'} type
 * @property {number} sharing
 *
 * @typedef {FixedPricing | SharedPricing} Pricing
 *
 * @typedef ProviderPricing
 * @property {number} id
 * @property {string} category
 * @property {string} name
 * @property {string} email
 * @property {string} country
 * @property {string} gender
 * @property {string} dob
 * @property {numbedr} available
 * @property {Pricing[]} price
 *
 * @typedef Service
 * @property {number} id
 * @property {string} service_name
 */

/**
 * @returns {Promise<{ rows: ProviderPricing[]; services: Service[] }>}
 */
const getData = async () => {
	const body = await makeRequest(`${uri}/billing/admin/prices`);
	return body;
};

const Providers = () => {
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);
	const [type, setType] = useState("all");
	const [noOfRows, setNoOfRows] = useState(15);
	const [available, setAvailable] = useState(false);
	const { data = { rows: [], services: [] }, isFetching } = useQuery("data", getData, {
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
	});
	const handleSnackBarShow = (message) => {
		setSnackbar({
			open: true,
			message,
		});
	};
	const filterData = useMemo(() => {
		const loSearch = search.toLocaleLowerCase();
		let searchFilter = data.rows.filter(
			(e) => String(e.id) === loSearch || e.email.toLocaleLowerCase().includes(loSearch) || e.name.toLocaleLowerCase().includes(loSearch)
		);
		if (available) searchFilter = searchFilter.filter((e) => e.available === 1);
		if (type === "all") return searchFilter;
		if (type === "no price") return searchFilter.filter((e) => e.price.length === 0);
		return [];
	}, [data, search, available, type]);
	
	const [paginated, pageCount] = useMemo(() => {
		const start = (page - 1) * noOfRows;
		return [filterData.slice(start, start + noOfRows), Math.ceil(filterData.length / noOfRows)];
	}, [filterData, noOfRows, page]);

	return (
		<Sidemenu role="admin">
			<div className={styles.provider_wrap}>
				<span className={styles.heading}>Providers</span>
				<div className={styles.filterBar}>
					<span style={{ fontSize: "17px" }}>Search&nbsp;&nbsp;</span>
					<OutlinedInput
						onChange={(e) => setSearch(e.target.value)}
						placeholder="enter name, email or keyword"
						size="small"
						value={search}
						style={{ maxWidth: "260px", width: "260px", background: "white" }}
					/>
					<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
						<InputLabel id="demo-select-small">Type</InputLabel>
						<Select
							labelId="demo-select-small"
							id="demo-select-small"
							value={type}
							label="View"
							style={{ textTransform: "capitalize" }}
							onChange={(e) => setType(e.target.value)}
						>
							<MenuItem value={"all"} key={"all"} style={{ textTransform: "capitalize" }}>
								all
							</MenuItem>
							{paginated &&
								["no price"].map((key) => (
									<MenuItem value={key} key={key} style={{ textTransform: "capitalize" }}>
										{key}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<FormControlLabel control={<Checkbox value={available} onChange={(e) => setAvailable(e.target.checked)} />} label="Available" />
				</div>
				{!isFetching ? (
					<Table data={paginated} serviceList={data.services} snackbarFunc={handleSnackBarShow} />
				) : (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							paddingTop: "1rem",
						}}
					>
						<CircularProgress />
					</div>
				)}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						padding: "30px 0px",
						justifyContent: "center",
					}}
				>
					<Pagination count={pageCount} onChange={(e, v) => setPage(v)} />
				</div>
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					open={snackbar.open}
					autoHideDuration={5000}
					onClose={() => setSnackbar({ open: false, message: "" })}
					message={snackbar.message}
				/>
			</div>
		</Sidemenu>
	);
};

export default Providers;
