import React, { Component } from 'react';
import Sidemenu from '../Sidemenu';
import { DataGrid, renderActionsCell } from '@mui/x-data-grid';
import { makeRequest, makeRequestNew } from '../../_actions/fetch';
import { uri } from '../../config';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SaveIcon from '@mui/icons-material/Save';
import * as jsonexport from 'jsonexport/dist';
import './custom.css';
import CircularProgress from '@mui/material/CircularProgress';

const today = new Date();

const parseDate = (dateString) => {
	const matches = dateString.match(/(\d{1,2})[/-](\d{1,2})[/-](\d{2,4})/);
	if (!matches) {
		return null;
	}
	const date = parseInt(matches[1]),
		month = parseInt(matches[2]),
		year = parseInt(matches[3].length === 2 ? '20' + matches[3] : matches[3]);

	if (year < 2022) {
		return null;
	}

	const final = new Date(`${month}-${date}-${year}`);

	if (isNaN(final)) {
		return null;
	}

	if (final > today) {
		return null;
	}

	return final;
};

class Billing extends Component {
	renderDetailsButton = (params) => {
		return (
			<IconButton aria-label='save' onClick={() => this.savePayment(params)}>
				<SaveIcon />
			</IconButton>
		);
	};

	columns = [
		{ field: 'id', headerName: 'ID', width: 100 },
		{
			field: 'name',
			headerName: 'Name',
			width: 100,
			sortable: false,
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 180,
			sortable: false,
		},
		{
			field: 'payable',
			headerName: 'Payable',
			type: 'number',
			width: 90,
			sortable: true,
		},
		{
			field: 'total_session',
			headerName: 'Total Sessions',
			width: 80,
			sortable: true,
		},
		{
			field: 'paid',
			headerName: 'Total Paid',
			width: 80,
			sortable: true,
		},
		{
			field: 'net_outstanding',
			headerName: 'Net Outstanding till Last Month',
			sortable: false,
			width: 150,
			valueFormatter: ({ value }) => value,
		},
		{
			field: 'payment_date',
			headerName: 'Payment Date',
			sortable: false,
			editable: true,
			width: 130,
			type: 'date',
			disableColumnMenu: true,
		},
		{
			field: 'payment_amount',
			headerName: 'Payment Made',
			sortable: false,
			type: 'number',
			editable: true,
			width: 120,
			disableColumnMenu: true,
		},
		{
			field: 'remarks',
			headerName: 'Remarks',
			sortable: false,
			editable: true,
			width: 250,
			disableColumnMenu: true,
		},
		{
			field: 'actions',
			headerName: 'Action',
			sortable: false,
			disableColumnMenu: true,
			renderCell: this.renderDetailsButton,
		},
	];

	newColumns = [
		{ field: 'id', headerName: 'ID', width: 100 },
		{
			field: 'name',
			headerName: 'Name',
			width: 130,
			disableColumnMenu: true,
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 200,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: 'payment_date',
			headerName: 'Payment Date',
			sortable: false,
			type: 'date',
		},
		{
			field: 'payment_amount',
			headerName: 'Payment Made',
			sortable: false,
			type: 'number',
			disableColumnMenu: true,
		},
		{
			field: 'remarks',
			headerName: 'Remarks',
			sortable: false,
			disableColumnMenu: true,
		},
	];

	constructor(props) {
		super(props);
		this.state = {
			rows: [],
			searchRows: [],
			snackbar: {
				open: false,
				message: '',
			},
			save: true,
			newData: [],
			error: [],
			loader: true,
			search: '',
		};
	}

	async componentDidMount() {
		this.getData();
	}

	async componentDidUpdate(_p, prevstate, _a) {
		if (prevstate.search != this.state.search) {
			const loCaseSearch = this.state.search.toLowerCase();
			const data = this.state.rows.filter(
				(e) => String(e.id).toLowerCase() === loCaseSearch || String(e.name).toLowerCase().includes(loCaseSearch) || String(e.email).toLowerCase().includes(loCaseSearch),
			);
			this.setState({ searchRows: [...data] });
		}
	}
	getData = async () => {
		this.setState({
			loader: false,
		});
		try {
			const response = await makeRequestNew(uri + `/billing/admin/billing`, 'GET');
			if (response.ok) {
				const body = await response.json();
				this.setState({
					rows: body.rows.sort((a, b) => b.net_outstanding - a.net_outstanding),
					searchRows: body.rows,
					loader: true,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	savePayment = async (params) => {
		const { row } = params;
		console.log(row);
		const { id, payment_date, payment_amount, remarks } = row;
		if (!id || !payment_date || !payment_amount) {
			this.setState({
				snackbar: {
					open: true,
					message: 'Invalid Data field',
				},
			});
			return;
		}
		try {
			const response = await makeRequestNew(uri + '/billing/admin/billing', 'POST', {
				provider_id: id,
				payment_date: payment_date.getTime(),
				payment_amount,
				remarks,
			});
			if (response.ok) {
				this.getData();
				this.setState({
					snackbar: {
						open: true,
						message: 'Payment Done Successfully',
					},
				});
			}
		} catch (err) {
			this.setState({
				snackbar: {
					open: true,
					message: 'Payment failed',
				},
			});
			console.log(err);
		}
	};

	downloadCsv = () => {
		const newRows = this.state.searchRows.map((e) => {
			delete e.clients;
			e['payment_date'] = '';
			e['payment_amount'] = '';
			e['remarks'] = '';
			return e;
		});
		jsonexport(newRows, function (err, csv) {
			const file = new Blob([csv], { type: 'text/csv' });
			const element = document.createElement('a');
			element.href = URL.createObjectURL(file);
			element.download = 'billingData' + Date.now() + '.csv';
			document.body.appendChild(element);
			element.click();
		});
	};

	uploadCsv = async (file) => {
		const data = await new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				resolve(e.target.result);
			};

			reader.onerror = (e) => reject(e.target.error);

			reader.readAsText(file);
		});

		let [keys, ...rest] = data
			.trim()
			.split('\n')
			.map((item) => item.split(','));

		keys = keys.map((e) => {
			return e.trim().toLowerCase();
		});

		const nextState = [];
		if (!keys.includes('payment_date')) {
			nextState.push('Payment_date field not found');
		}
		if (!keys.includes('payment_amount')) {
			nextState.push('Payment_amount field not found');
		}
		if (!keys.includes('remarks')) {
			nextState.push('Remarks field not found');
		}

		this.setState({
			error: [...nextState],
		});

		if (nextState.length > 0) {
			this.setState({
				newData: [],
			});
			return;
		}
		const formedArr = rest.map((item) => {
			const object = {};
			keys.forEach((key, index) => (object[key] = item.at(index).trim()));
			return object;
		});

		const finalArr = [],
			errorLists = [];

		formedArr.forEach((e) => {
			const date = parseDate(e['payment_date']);
			const cond = e.payment_date == '' && e.payment_amount == '';
			if (cond) return;
			const errorL = [];

			if (date == null) {
				errorL.push(`Invaild Date in record with providerId-${e.id}`);
			}
			if (e.provider_id == '') {
				errorL.push(`Invaild Id in record with providerId-${e.id}`);
			}
			if (e.payment_amount == '' || /[a-zA-Z]/g.test(e.payment_amount)) {
				errorL.push(`Invaild payment_amount in record with providerId-${e.id}`);
			}

			errorL.length && errorLists.push(...errorL);

			if (errorL.length > 0) {
				return;
			}

			const newObj = {};
			newObj['id'] = e['id'];
			newObj['name'] = e['name'];
			newObj['email'] = e['email'];
			newObj['payable'] = e['payable'];
			newObj['total_session'] = e['total_session'];
			newObj['paid'] = e['paid'];
			newObj['net_outstanding'] = e['net_outstanding'];
			newObj['payment_date'] = date.toLocaleDateString('en-IN');
			newObj['payment_amount'] = e['payment_amount'];
			newObj['remarks'] = e['remarks'];

			finalArr.push(newObj);
		});

		this.setState({
			save: true,
			newData: [...finalArr],
			error: errorLists,
		});
	};

	saveChanges = async () => {
		this.setState({
			save: true,
			loader: false,
		});
		const data = this.state.newData.map((e) => {
			const obj = {};
			obj['provider_id'] = parseInt(e['id']);
			obj['payment_amount'] = parseInt(e['payment_amount']);
			obj['payment_date'] = parseDate(e['payment_date']);
			obj['remarks'] = e['remarks'];
			return obj;
		});
		try {
			const response = await makeRequestNew(uri + '/dash/payments', 'POST', {
				data,
			});
			if(!response.ok) throw new Error("Payment failed");
			await this.getData();
		} catch (err) {
			this.setState({
				snackbar: {
					open: true,
					message: 'Payment failed',
				},
			});
		}
		this.setState({
			newData: [],
			error: [],
			loader: true,
		});
	};

	cancelUpdate = () => {
		this.setState({
			save: true,
			newData: [],
			error: [],
		});
	};

	render() {
		return (
			<Sidemenu role='admin'>
				<div className='home_wrapper'>
					<h3>Billing</h3>
					<div style={{ display: 'flex', columnGap: 40, alignItems: 'center' }}>
						<OutlinedInput
							placeholder='Search'
							size='small'
							style={{ maxWidth: '260px', width: '260px', background: 'white' }}
							value={this.state.search}
							onChange={(e) => this.setState({ search: e.target.value })}
						/>
					</div>
					<br />
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							ColumnGap: '10px',
							color: 'red',
							fontWeight: '600',
						}}
					>
						{this.state.error?.map((e, i) => (
							<div key={i}>• {e}</div>
						))}
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginBottom: '10px',
							columnGap: '10px',
						}}
					>
						<div hidden={this.state.loader}>
							<CircularProgress />
						</div>
						{this.state.newData.length > 0 ? (
							<>
								<Button variant='contained' onClick={this.saveChanges}>
									Submit
								</Button>
								<Button variant='contained' color='error' onClick={this.cancelUpdate}>
									Cancel
								</Button>
							</>
						) : (
							<>
								<Button onClick={this.downloadCsv} variant='contained' disableElevation>
									Download
								</Button>
								<Button variant='outlined' component='label'>
									Upload
									<input type='file' accept='.csv' hidden id='upload input' onChange={(e) => this.uploadCsv(e.target.files[0])} />
								</Button>
							</>
						)}
					</div>
					<div style={{ height: '700px', width: '100%' }}>
						{this.state.newData.length > 0 && (
							<>
								<div
									style={{
										marginBottom: '1rem',
										fontWeight: '500',
										fontSize: 'x-large',
									}}
								>
									Preview
								</div>
								<DataGrid rows={this.state.newData} columns={this.newColumns} pageSize={15} />
							</>
						)}
						{!this.state.newData.length > 0 && <DataGrid rows={this.state.searchRows} columns={this.columns} pageSize={15} disableSelectionOnClick />}
						<Snackbar
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							open={this.state.snackbar.open}
							autoHideDuration={5000}
							onClose={() => this.setState({ snackbar: { open: false, message: '' } })}
							message={this.state.snackbar.message}
						/>
					</div>
				</div>
			</Sidemenu>
		);
	}
}

export default Billing;
