import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: true,
	isAuthenticated: null,
	token: localStorage.getItem('token'),
	error: '',
	user: null,
	type: null,
};

export const authSlice = createSlice({
	name: 'authSlice',
	initialState,
	reducers: {
		AUTH_SUCCESS: (state, action) => {
			state.isAuthenticated = true;
			state.loading = false;
			state.type = 'superuser';
		},
		AUTH_FAIL: (state, _) => {
			state.isAuthenticated = false;
			state.loading = false;
		},
		LOGIN_SUCCESS: (state, action) => {
			localStorage.setItem('token', action.payload.token);
			state.token = action.payload.token;
			state.isAuthenticated = true;
			state.loading = false;
			state.type = action.payload.type;
		},
		LOGIN_FAILED: (state, action) => {
			state.error = action.payload.message;
		},
		LOGOUT: (state) => {
			localStorage.removeItem('token');
			state.isAuthenticated = false;
			state.loading = false;
		},
	},
});

export const { AUTH_SUCCESS, LOGIN_SUCCESS, LOGOUT, AUTH_FAIL, LOGIN_FAILED } = authSlice.actions;

export default authSlice.reducer;
