import { Button, FormControl, InputLabel, Link, MenuItem, Select, TableCell } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { DataGrid } from '@mui/x-data-grid';
import { useMemo, useState, memo } from 'react';
import { useQuery } from 'react-query';
import { makeRequestNew } from '../../_actions/fetch';
import { uri } from '../../config';
import Sidemenu from '../Sidemenu';
import styles from './ChatRooms.module.css';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

/**
 *
 * @returns {{rooms:{id:number; name:string; created_at:string, service_id:number; last_message_time:string; message_id:number; provider:{id:number; name:string; email:string; type:string}, client:{id:number; name:string; email:string; type:string}}[]}}
 */
const getAllRooms = async () => {
	const response = await makeRequestNew(uri + '/chat/admin/all-rooms');
	const body = await response.json().catch((e) => null);
	if (!response.ok) {
		throw new Error('something went wrong');
	}
	return body;
};

/**
 * @typedef User
 * @property {number} id
 * @property {string} email
 * @property {string} name
 *
 * @param {User}
 * @returns {Promise<{token:string}>}
 */
const getUserJwt = async ({ id, email, name }) => {
	const response = await makeRequestNew(uri + '/auth/jwt', 'POST', {
		id,
		email,
		name,
	});
	const body = await response.json().catch(() => null);
	if (!response.ok) {
		throw new Error(body.message || 'something went wrong');
	}
	return body;
};

const ChatViewCell = ({ row }) => {
	const { provider, id } = row;
	const [type, setType] = useState('view');
	const history = useHistory();
	const { refetch, isError } = useQuery('jwt', () => getUserJwt(provider), { refetchOnWindowFocus: false, refetchOnReconnect: false, enabled: false });
	const handleOnClick = async (e) => {
		e.preventDefault();

		if (type == 'view') {
			history.push(`/chat/rooms/${id}`);
			return;
		}
		try {
			const { data } = await refetch();
			if (isError) throw new Error('something went wrong');
			const anchor = document.createElement('a');
			anchor.target = '_blank';
			anchor.href = `http://web.mantracare.com/chat?token=${data?.token}`;
			anchor.click();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		
		<FormControl size='small' sx={{ width: 90, display: 'table-row' }}>
			<InputLabel id='demo-simple-select-label'>Chat</InputLabel>
			<Select labelId='demo-simple-select-label' id='demo-simple-select' value={type} label='Chat' sx={{ marginRight: 1 }} onChange={(e) => setType(e.target.value)}>
				<MenuItem value={'view'}>View</MenuItem>
				<MenuItem value={'full'}>Full</MenuItem>
			</Select>
			<Button onClick={handleOnClick}>Click</Button>
		</FormControl>
	);
};

const columns = [
	{
		field: 'id',
		header: 'Room Id',
		width: 80,
		type: 'number',
		sortable: true,
	},
	{
		field: 'name',
		header: 'Room Name',
		width: 120,
		type: 'string',
		sortable: false,
	},
	{
		field: 'client',
		header: 'Client',
		width: 90,
		type: 'number',
		renderCell: ({ value }) => <div>{value?.id}</div>,
	},
	{
		field: 'provider',
		header: 'Provider',
		width: 90,
		type: 'number',
		renderCell: ({ value }) => <div>{value?.id}</div>,
	},
	{
		field: 'service_id',
		header: 'Service Id',
		width: 90,
		type: 'number',
		sortable: false,
	},
	{
		field: 'locked',
		header: 'Status',
		width: 90,
		type: 'number',
		sortable: false,
		renderCell: ({ value }) => (value == 1 ? <LockIcon color='error' /> : <LockOpenIcon color='success' />),
	},
	{
		field: 'created',
		header: 'created on',
		width: 100,
		type: 'date',
		sortable: true,
		valueFormatter: ({ value }) => new Date(value).toLocaleDateString('en-IN'),
	},
	{
		field: 'last_message_time',
		header: 'Last Message Time',
		width: 180,
		valueFormatter: ({ value }) => new Date(value).toLocaleString('en-IN'),
	},
	{
		field: 'Chat',
		header: 'Chat',
		width: 180,
		renderCell: ChatViewCell,
	},
];

const ChatRooms = () => {
	const [search, setSearch] = useState('');
	const { data, isError, isFetching } = useQuery(['rooms'], getAllRooms, {
		refetchOnWindowFocus: false,
		enabled: true,
	});

	const filterData = useMemo(() => {
		if (!data?.rooms) return [];
		const { rooms } = data;
		const trimSearch = search.trim();
		return rooms.filter(
			(val) =>
				val.service_id == trimSearch ||
				val.id == trimSearch ||
				val.name.includes(trimSearch) ||
				val.client?.id == trimSearch ||
				val.client?.name.includes(trimSearch) ||
				val.provider?.id == trimSearch,
		);
	}, [data?.rooms, search]);

	if (isError) return <div>Somthing went wrong</div>;

	return (
		<Sidemenu role='admin'>
			<div className={styles.provider_wrap}>
				<span className={styles.heading}>Chat Rooms</span>
				<div className={styles.filterBar}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<OutlinedInput
							onChange={(e) => setSearch(e.target.value)}
							placeholder='Search'
							size='small'
							value={search}
							style={{ maxWidth: '260px', width: '260px', background: 'white' }}
						/>
					</div>
				</div>
				<div style={{ height: '700px', width: '100%' }}>
					<DataGrid
						initialState={{ sorting: { sortModel: [{ field: 'last_message_time', sort: 'desc' }] } }}
						rows={filterData}
						columns={columns}
						pageSize={15}
						pagination
						disableSelectionOnClick
						loading={isFetching}
					/>
				</div>
			</div>
		</Sidemenu>
	);
};

export default ChatRooms;
