const therapy = ['ADHD','Abuse','Addiction','Anger','Anxiety','Bipolar','Child Or Teen','Depression','Family','Insomnia','LGBTQ+','OCD','Panic Attacks','Relationships','Sex Therapy','Spiritual','Stress Management','Trauma And PTSD','Workplace Isssues'];
const physio = ['Back Pain','Hand / Wrist Pain','Knee Pain','Hip Pain','Neck Pain','Shoulder Pain','Elbow Pain','Leg Pain','Foot / Ankle Pain','Arthiritis','Orthopedic','Cardio-Respiratory','Pediatric','Sports Injury','Vestibular Or Vertigo','Geriatric'];
const diet = ['Weight Loss','Weight Gain','PCOS Diet','Diabetes Diet','Hypertension Diet','Keto Diet','Vegan Diet','Low-Carb Diets','Dukan Diet','Atkins Diet','HCG Diet','Intermittent Fasting','Mediterranean Diet','Paleo Diet'];
const yoga = ['Yoga For High BP','Yoga For Diabetes','Postnatal Yoga','Yoga For Wellness','Yoga For PCOS','Yoga For Backpain','Yoga For Pregnancy','Hatha Yoga','Ashtanga Yoga','Bikram Yoga','Iyengar Yoga','Kundalini Yoga','Restorative Yoga','Yin Yoga','Pranayam','Vinyasa Yoga','Power Yoga'];
const fitness = ['Exercise','Aerobics','Zumba','Muscle building','Weight Loss','Weight Gain','PCOS','Diabetes','Hypertension','Intermittent Fasting'];

export const specializationArray =  [...new Set([...therapy, ...physio, ...diet, ...yoga, ...fitness])].sort((a, b) => a.localeCompare(b))

const country = ['Afghanistan', 'Albania', 'Algeria', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'British Virgin Islands', 'Brunei', 'Bulgaria', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo', 'Costa Rica', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Dominica', 'Dominican Republic', 'Egypt', 'Estonia', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Greenland', 'Guatemala', 'Guinea', 'Guyana', 'Haiti', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Kazakhstan', 'Kenya', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Liberia', 'Macau', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mauritius', 'Mexico', 'Monaco', 'Mongolia', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'Norway', 'Oman', 'Others', 'Pakistan', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Samoa', 'Saudi Arabia', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'Spain', 'Sri Lanka', 'Sudan', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Trinidad and Tobago', 'Turkey', 'Turkmenistan', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan', 'Vatican', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'];

export const countryArray =  [...country]

const category = ['Psychologist/Therapist','Nutritionist/Dietitian', 'Psychiatrist', 'Physio Therapist', 'Yoga instructor','Meditation/Mindfullness coach', 'Fitness Coach']

export const categoryArray =  [...category]