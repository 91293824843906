export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const paddedNum = (num, pad) => {
	let str = `${num}`;
	for (let i = str.length; i < pad; i++) {
		str = '0' + str;
	}
	return str;
};

export const readableTime = (time) => {
	if (typeof time === 'string') {
		time = new Date(time);
	} else if (typeof time === 'number') {
		time = new Date(time);
	}

	const today = new Date();

	if (!time) {
		return 'Fallback';
	}

	const date = time.getDate(),
		day = time.getDay(),
		month = time.getMonth(),
		year = time.getFullYear(),
		hour = time.getHours(),
		minute = time.getMinutes();

	const hPad = paddedNum(hour, 2),
		mPad = paddedNum(minute, 2);

	const diff = today.getTime() - time.getTime();

	if (diff < DAY && date === today.getDate()) {
		return `${hPad}:${mPad}`;
	}
	// if (diff < DAY * 2) {
	//     const yesterday = new Date(today);
	//     yesterday.setDate(today.getDate() - 1);
	//     if (yesterday.getDate() === date) return `yesterday at ${hPad}:${mPad}`;
	// }
	if (diff < WEEK) {
		return `${WEEKDAYS[day]} at ${hPad}:${mPad}`;
	}
	if (year === today.getFullYear()) {
		return `${date} ${MONTHS[month]} at ${hPad}:${mPad}`;
	}

	return `${date} ${MONTHS[month]}, ${year} at ${hPad}:${mPad}`;
};

export function getArrayMessage(arrString) {
	try {
		const arr = JSON.parse(arrString);
		let str = '';

		arr.forEach((e) => {
			if (e?.type == 'text') str += e?.text + ' ';
			else if (e?.type == 'link' || e?.type == 'button') {
				str += e?.child?.text + ' ';
			}
		});

		return str;
	} catch (ee) {
		return ""+arrString
	}
}

export const fileImgLink = 'https://s7d9.scene7.com/is/image/NewellRubbermaid/dymo-carousel-tile_office-icons_all-breakpoints?fmt=jpeg';
