import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { specializationArray, countryArray, categoryArray } from './specialization';

export default function Filter({ handleFilter, handleReset, filter, setFilter }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [avail, setAvail] = React.useState({ label: 'All', val: null });

	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const reset = () => {
		handleReset();
		setAvail({ label: 'All', val: null });
	};

	const confirm = () => {
		handleFilter(filter);
		handleClose();
	};

	return (
		<React.Fragment>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', marginRight: '10px' }}>
				<Tooltip title='Filter'>
					<FilterAltIcon
						style={{ fontSize: '30px', color: '#282828', cursor: 'pointer' }}
						onClick={handleClick}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
					/>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id='filter'
				open={open}
				onClose={handleClose}
				// onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 8,
							width: 12,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<Paper sx={{ width: 420 }} elevation={0}>
					<MenuList dense>
						<MenuItem>
							<Autocomplete
								multiple
								style={{ minWidth: '100%' }}
								options={specializationArray}
								value={filter.specialization}
								onChange={(e, v) => setFilter({ ...filter, specialization: v })}
								renderInput={(params) => <TextField {...params} label='Specialization' placeholder='Add Tags' />}
							/>
						</MenuItem>
						<Divider />
						<MenuItem>
							<Autocomplete
								style={{ minWidth: '100%' }}
								options={countryArray}
								onChange={(e, v) => setFilter({ ...filter, country: v })}
								value={filter.country}
								renderInput={(params) => <TextField {...params} label='Country' placeholder='Add Tags' />}
							/>
						</MenuItem>
						<Divider />
						<MenuItem>
							<Autocomplete
								style={{ minWidth: '100%' }}
								options={categoryArray}
								onChange={(e, v) => setFilter({ ...filter, category: v })}
								value={filter.category}
								renderInput={(params) => <TextField {...params} label='Category' placeholder='Category' />}
							/>
						</MenuItem>
						<Divider />
						<MenuItem>
							<Autocomplete
								style={{ minWidth: '100%' }}
								options={[
									{ label: 'All', val: null },
									{ label: 'Not Available', val: 0 },
									{ label: 'Available', val: 1 },
								]}
								getOptionLabel={(option) => option.label}
								onChange={(e, v) => {
									setFilter({ ...filter, availability: v.val });
									setAvail(v);
								}}
								value={avail}
								renderInput={(params) => <TextField {...params} label='Availability' placeholder='Availability' />}
							/>
						</MenuItem>
						<ButtonGroup variant='text' aria-label='text button group' style={{ float: 'right', padding: '10px' }}>
							<Button onClick={() => reset()}>Reset</Button>
							<Button onClick={() => confirm()}>Apply</Button>
						</ButtonGroup>
					</MenuList>
				</Paper>
			</Menu>
		</React.Fragment>
	);
}
