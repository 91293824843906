import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Sidemenu from './Sidemenu';

class HomePage extends Component{
    constructor(props){
        super(props);
        if(props?.auth.type === 'business'){
            <Redirect to="/corporate" />
        }
    }
    render() {
        return (
            <Sidemenu role="admin">
                <div className='home_wrapper'>
                    <h3>Dashboard</h3>
                </div>
            </Sidemenu>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(HomePage);