import { useState, memo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment/moment";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { useMutation } from "react-query";
import Service from "./helper";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CircularProgress from "@mui/material/CircularProgress";
import { presentDate } from "./helper";

/**
 *
 * @param {*} props
 * @type {React.FC<props>}
 * @returns {React.ReactElement}
 */
const DatePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
      <DateTimePicker
        {...props}
        renderInput={(props) => <TextField {...props} />}
      />
    </LocalizationProvider>
  );
};

const AddSessionDialog = memo(
  ({
    open,
    handleClose,
    proUsData,
    handleSnackBarShow,
    addnewSessionLocal,
  }) => {
    const [allInputs, setAllInputs] = useState({
      provider_id: "",
      user_id: "",
      service_id: "",
      duration: "",
      datetime: moment(presentDate),
      type: "video",
    });
    const { mutate, isLoading, error } = useMutation(Service.addSession, {
      onSuccess: () => {
        handleClose();
        addnewSessionLocal();
        handleSnackBarShow("New Session Added");
      },
    });
    const providers = proUsData.providersWithId;
    const users = proUsData.usersWithId;
    const services = proUsData.services;
    const handleSubmit = () => {
      const errorInput = [];
      Object.keys(allInputs).map(
        (e) =>
          (allInputs[e] === "" || allInputs[e] === null) && errorInput.push(e)
      );
      !allInputs.datetime.isValid() && errorInput.push("requested");
      if (errorInput.length > 0) {
        handleSnackBarShow(`Invalid Input in field ${errorInput.join()}`);
        return;
      }
      mutate(allInputs);
    };
    const onClose = () => {
      setAllInputs({
        provider_id: "",
        user_id: "",
        service_id: "",
        duration: "",
        datetime: moment(presentDate),
        type: "video",
      });
      handleClose();
    };
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: "100%" } }}
      >
        <DialogTitle>Add a Session</DialogTitle>
        <DialogContent>
          <Autocomplete
            disabled={isLoading}
            onChange={(_, value) =>
              value == null
                ? setAllInputs({ ...allInputs, provider_id: "" })
                : setAllInputs({ ...allInputs, provider_id: value.user_id })
            }
            getOptionLabel={(option) =>
              option.user_id + "-" + option.name + " - " + option.email
            }
            renderInput={(params) => (
              <TextField {...params} label="Select Provider" />
            )}
            options={providers}
            sx={{ margin: "10px" }}
          />
          <Autocomplete
            disabled={isLoading}
            onChange={(_, value) =>
              value == null
                ? setAllInputs({ ...allInputs, user_id: "" })
                : setAllInputs({ ...allInputs, user_id: value.id })
            }
            getOptionLabel={(option) =>
              option.id + "-" + option.name + " - " + option.email
            }
            renderInput={(params) => (
              <TextField {...params} label="Select User" />
            )}
            options={users}
            sx={{ margin: "10px" }}
          />
          <Autocomplete
            disabled={isLoading}
            onChange={(_, value) =>
              value == null
                ? setAllInputs({ ...allInputs, service_id: "" })
                : setAllInputs({ ...allInputs, service_id: value.id })
            }
            getOptionLabel={(option) => option.id + " - " + option.service_name}
            renderInput={(params) => (
              <TextField {...params} label="Select Service" />
            )}
            options={services}
            sx={{ margin: "10px" }}
          />
          <Autocomplete
            disabled={isLoading}
            onChange={(_, value) =>
              value == null
                ? setAllInputs({ ...allInputs, type: "" })
                : setAllInputs({ ...allInputs, type: value })
            }
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Select Session Type" />
            )}
            options={["video", "chat"]}
            sx={{ margin: "10px" }}
          />
          <TextField
            disabled={isLoading}
            value={allInputs.duration}
            sx={{ margin: "10px", width: "97%" }}
            onChange={(v) =>
              setAllInputs({ ...allInputs, duration: v.target.value })
            }
            label="Durations(MIN)"
            type="number"
          />
          <DatePicker
            disabled={isLoading}
            label={"Requested"}
            value={allInputs.datetime}
            onChange={(value) =>
              setAllInputs({ ...allInputs, datetime: value })
            }
          />
        </DialogContent>
        <DialogActions>
          {isLoading && <CircularProgress />}
          {error && <div style={{ color: "red" }}>{error.message}</div>}
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default AddSessionDialog;
