import { CircularProgress, Snackbar } from '@mui/material';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { uri } from '../../config';
import { makeRequestNew } from '../../_actions/fetch';
import Sidemenu from '../Sidemenu';

const defaultImg = 'https://static.vecteezy.com/system/resources/previews/010/160/858/original/who-icon-sign-symbol-design-free-png.png';

/**
 *
 * @typedef {{ id: number; plan_start: string; plan_end: string; product_id: number }} Order
 *
 * @typedef {{ id: number; name: string; image?: string; comment?: string }} Product
 *
 * @typedef {{ id: number; name: string, chatLeft: number; videoLeft: number; }} Service
 *
 * @typedef {{ product_id: number, service_id: number }} ProductService
 *
 * @typedef {{ service_id: number; notified?: number[], queue: [number, number][] }} Score
 *
 * @typedef {{ id: number; image: string; name: string }} Provider
 *
 *
 * @typedef Preference
 * @property {null} id
 * @property {string} name
 * @property {string} phone
 * @property {string} email
 * @property {string} language
 * @property {string} country
 * @property {string} gender
 * @property {string} age
 * @property {string} religion
 * @property {string} service_lookup
 * @property {number} service_id
 * @property {number} user_id
 * @property {string} other
 *
 */

/**
 *
 * @param {number} user_id
 * @returns {Promise<{
 *      user: { id: number; img?: string; email: string; name: string, corp: number | null };
 *      orders: Order[];
 *      services: Service[];
 * 		productToService: ProductService[];
 * 		products: Product[];
 *      corp: { corp: string; logo: string | null };
 * 		scores: Score[];
 * 		providers: Provider[];
 * 		preferences: Preference[];
 * }>}
 */
const getUserData = async (user_id) => {
	const response = await makeRequestNew(`${uri}/dash/users/${user_id}`);

	const body = await response.json().catch(() => null);

	if (!response.ok) {
		throw new Error(body?.message || 'Request Failed');
	}

	return body;
};

/**
 *
 * @param {number} user_id
 * @param {number} service_id
 * @returns {Promise<{ status: boolean }>}
 */
const calculate = async (user_id, service_id) => {
	const response = await makeRequestNew(`${uri}/dash/users/${user_id}/${service_id}/calculate`);

	const body = await response.json().catch(() => null);

	if (!response.ok) {
		throw new Error(body?.message || 'Request Failed');
	}

	return body;
};

/** @type {React.FC<import('react-router-dom').RouteComponentProps<{}>>} */
const User = ({ match }) => {
	const userID = match.params.userID;
	const [selected, setSelected] = useState(null);
	const [snackShow, setSnackShow] = useState({
		open: false,
		message: '',
	});

	const { data, isLoading, refetch } = useQuery(
		['User', userID],
		async () =>
			getUserData(userID).then((data) => {
				const orders = data.orders.map((order) => {
					const product = data.products.find((p) => p.id === order.product_id);
					const services = data.productToService.filter((ps) => ps.product_id === order.product_id).map((ps) => ps.service_id);

					return {
						...order,
						product,
						services: data.services.filter((s) => services.includes(s.id)),
					};
				});

				const score = data.scores?.map((sc) => {
					const service = data.services.find((s) => s.id === sc.service_id);
					return { ...sc, service };
				});

				if (data.services && data.services.length) setSelected(data.services[0].id);

				return {
					...data,
					orders,
					score,
				};
			}),
		{ staleTime: Infinity },
	);

	const mutation = useMutation(
		/**
		 *
		 * @param {{ user_id: number; service_id: number }} param0
		 * @returns
		 */
		async ({ user_id, service_id }) => {
			const response = await calculate(user_id, service_id);

			if (!response.status) {
				throw new Error('Failed');
			}
		},
		{
			onSuccess: () => {
				refetch();
				mutation.reset();
			},
			onError: (e) => setSnackShow({ message: e.message, open: true }),
		},
	);

	const selectedScore = useMemo(() => {
		if (!data || !data.score) return;
		const d = data.score?.find((sc) => sc.service_id === selected);

		if (!d) return;

		return {
			service_id: d.service_id,
			service: d.service,
			notified: d.notified?.map((p) => data.providers?.find((prov) => prov.id === p)),
			queue: d.queue.map(([p, score]) => ({ ...data.providers?.find((prov) => prov.id === p), score, provider_id: p })),
		};
	}, [selected, data]);

	const preference = useMemo(() => {
		if (!data || !data.preferences) return;
		return data.preferences.find((pref) => pref.service_id === selected);
	}, [data, selected]);

	const service = useMemo(() => {
		if (!data || !data.services) return;
		return data.services.find((s) => s.id == selected);
	});

	if (isLoading || mutation.isLoading) {
		return (
			<Sidemenu role='admin'>
				<div className='h-full w-full flex items-center justify-center'>
					<CircularProgress color='info' />
				</div>
			</Sidemenu>
		);
	}

	return (
		<Sidemenu role='admin'>
			<div className='max-h-screen'>
				<div className='grid grid-cols-2'>
					<div className='p-8 flex col-span-1'>
						<div className='h-48 w-48 rounded-full overflow-hidden'>
							<img src={data.user.img || defaultImg} className='h-full w-full object-cover' />
						</div>
						<div className='pl-10 pt-8'>
							<div>
								<span className='text-sky-600 text-xl font-semibold'>#{data.user.id}&nbsp;</span>
								<span className='text-sky-600 text-2xl font-semibold'>{data.user.name}</span>
							</div>
							<span className='mt-2 text-gray-600'>{data.user.email}</span>
							<br />
							{!!data.corp && <span className='inline-block my-2 bg-orange-500 text-white rounded-md py-1 px-5'>{data.corp.corp}</span>}
						</div>
					</div>
					<Preference preference={preference} />
				</div>
				<div className='mt-4 px-8 grid grid-cols-12'>
					{!!data?.services?.length && (
						<div className='col-span-8 px-4'>
							<div className='text-center font-semibold mb-3'>Allotment Ranks</div>
							<div className='flex'>
								{data.services?.map((service) => (
									<div
										onClick={() => setSelected(service.id)}
										key={service.id}
										className={`flex-1 text-center py-2 rounded-md cursor-pointer text-sm ${
											service.id === selected ? 'bg-blue-300 text-blue-800 font-semibold' : 'bg-slate-300 mx-1'
										}`}
									>
										{service.name}
									</div>
								))}
							</div>
							<div className='mt-4'>
								<div className='flex items-center'>
									<div className='font-bold text-lg text-center'>Chat</div>
									<div>&nbsp; = {service.chatLeft} sessions left</div>
								</div>
								<div className='flex items-center'>
									<div className='font-bold text-lg text-center'>Video</div>
									<div>&nbsp; = {service.videoLeft} sessions left</div>
								</div>
							</div>
							{selectedScore?.notified && (
								<Collapsible title={'Notified'}>
									{selectedScore.notified.map((provider, i) => (
										<Provider provider={provider} key={provider.id} num={i + 1} />
									))}
								</Collapsible>
							)}
							{selectedScore?.queue && (
								<Collapsible title={'Queue'}>
									{selectedScore.queue.map((provider, i) => (
										<Provider provider={provider} key={provider.provider_id} num={i + 1} />
									))}
								</Collapsible>
							)}
							{!selectedScore && (
								<div className='mt-3'>
									Not calculated yet.&nbsp;
									<span className='text-blue-600 cursor-pointer' onClick={() => mutation.mutate({ user_id: userID, service_id: selected })}>
										Calculate?
									</span>
								</div>
							)}
						</div>
					)}

					<div className='col-span-4'>
						<span className='text-lg font-semibold col-span-full'>Orders</span>
						{data.orders?.length ? data.orders.map((order) => <Order order={order} key={order.id} />) : <div className=''>No orders found</div>}
					</div>
				</div>
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={snackShow.open}
				autoHideDuration={5000}
				onClose={() => setSnackShow({ open: false, message: '' })}
				message={snackShow.message}
			/>
		</Sidemenu>
	);
};

/** @type {React.FC<{ preference: Preference | null }>} */
const Preference = ({ preference }) => {
	return (
		<div className='col-span-1 p-8'>
			<div className='text-2xl px-3 font-semibold text-blue-600'>Preferences</div>
			{preference ? (
				<table>
					<tr>
						<td className='px-3'>Age</td>
						<td>:</td>
						<td className='px-3'>{preference.age}</td>
					</tr>
					<tr>
						<td className='px-3'>Country</td>
						<td>:</td>
						<td className='px-3'>{preference.country}</td>
					</tr>
					<tr>
						<td className='px-3'>Language</td>
						<td>:</td>
						<td className='px-3'>{preference.language}</td>
					</tr>
					<tr>
						<td className='px-3'>Religion</td>
						<td>:</td>
						<td className='px-3'>{preference.religion}</td>
					</tr>
					<tr>
						<td className='px-3 align-top'>Gender</td>
						<td className='align-top'>:</td>
						<td className='px-3'>{preference.gender}</td>
					</tr>
					<tr>
						<td className='px-3 align-top'>Tags</td>
						<td className='align-top'>:</td>
						<td className='px-3'>{preference.service_lookup}</td>
					</tr>
				</table>
			) : (
				<span className='text-zinc-500 px-3'>Preference not filled</span>
			)}
		</div>
	);
};

const Provider = ({ provider, num }) => {
	return (
		<div className='flex p-2 m-1 rounded-md bg-white items-center'>
			<span className='text-blue-800 font-semibold pr-2'>{num}.</span>
			<div className='h-10 w-10 rounded-full overflow-hidden'>
				<img
					src={provider.img || defaultImg}
					alt={`${provider.name}#${provider.id}`}
					className='h-full w-full object-cover'
					onError={function (e) {
						e.currentTarget.src = defaultImg;
					}}
				/>
			</div>
			<div className='px-3 flex-1'>
				<Link to={`/provider/${provider.id}`}>
					<span className='text-blue-600 font-semibold mr-1 text-sm'>#{provider.id}</span>
				</Link>
				<span className=''>{provider.name}</span>
				<div className='text-gray-700'>Score: {provider.score}</div>
			</div>
		</div>
	);
};

const Collapsible = ({ title, children }) => {
	const [open, setOpen] = useState(true);
	return (
		<div className={`my-1 border-gray-200 rounded-md border-2 transition-all duration-300 overflow-hidden mt-3 ${open ? 'max-h-[1000px]' : 'max-h-[44px]'}`}>
			<div className='p-2 cursor-pointer' onClick={() => setOpen((o) => !o)}>
				<span className=''>{title}</span>
			</div>
			<div className='overflow-auto'>
				<div>{children}</div>
			</div>
		</div>
	);
};

/** @type {React.FC<{ order: Order & { product?: Product; services: Service[] } }>} */
const Order = ({ order }) => {
	const [open, setOpen] = useState(false);

	return (
		<div className={`my-1 border-gray-200 rounded-md border-2 transition-all duration-300 overflow-hidden ${open ? 'max-h-52' : 'max-h-[44px]'}`}>
			<div className='p-2 cursor-pointer' onClick={() => setOpen((o) => !o)}>
				<span className=' text-slate-500'>
					<span className='text-sm'>#{order.id}</span>
					&nbsp;&nbsp;
					<span className='text-black'>{order.product.name}</span>
				</span>
			</div>
			<div className='p-3 text-sm'>
				<span className='text-sm'>
					{moment(order.plan_start).format('DD-MM-YYYY')} - {moment(order.plan_end).format('DD-MM-YYYY')}
				</span>
				<br />
				<span>Product ID: {order.product_id}</span>
				<br />
				<span>Services:&nbsp;&nbsp;{order.services.map((s) => s.name).join(', ')}</span>
			</div>
		</div>
	);
};

export default User;
