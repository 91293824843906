import { makeRequest, makeRequestNew } from '../../_actions/fetch';
import { uri } from '../../config';
import { GridColDef } from '@mui/x-data-grid';
import RenderCellExpand from '../shared/RenderCellExpand';

/**
 * @type {GridColDef[]}
 */
export const columns = [
	{
		field: 'session_type',
		headerName: 'Session Type',
		width: 80,
		sortable: false,
		disableColumnMenu: true,
		renderCell: ({ value }) =>
			value === 'chat' ? (
				<div
					style={{
						fontWeight: 800,
						color: '#0096FF',
					}}
				>
					Chat
				</div>
			) : (
				<div
					style={{
						fontWeight: 600,
						color: '#0047AB',
					}}
				>
					video
				</div>
			),
	},
	{
		field: 'id',
		headerName: 'ID',
		type: 'number',
		width: 65,
	},
	{
		field: 'user_id',
		headerName: 'User Id',
		type: 'number',
		width: 65,
	},
	{
		field: 'manual',
		headerName: 'Manual',
		width: 60,
		disableColumnMenu: true,
		renderCell: ({ value }) =>
			value ? (
				<div
					style={{
						fontWeight: 800,
						color: 'red',
					}}
				>
					1
				</div>
			) : (
				<div
					style={{
						fontWeight: 600,
						color: 'black',
					}}
				>
					0
				</div>
			),
	},
	{
		field: 'order_id',
		headerName: 'Order Id',
		width: 80,
		type: 'number',
		valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
	},
	{
		field: 'product_name',
		headerName: 'product name',
		width: 190,
		sortable: false,
		valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
	},
	{
		field: 'user_name',
		headerName: 'User Name',
		width: 160,
		sortable: false,
	},
	{
		field: 'provider_id',
		headerName: 'Provider Id',
		width: 80,
		type: 'number',
		sortable: false,
	},
	{
		field: 'provider_name',
		headerName: 'Provider Name',
		width: 160,
		sortable: false,
	},
	{
		field: 'service_name',
		headerName: 'Service',
		width: 100,
		sortable: false,
	},
	{
		field: 'session_date',
		headerName: 'Session Date',
		width: 200,
		type: 'date',
		sortable: false,
		valueFormatter: ({ value }) => new Date(value),
	},
	{
		field: 'entry_date',
		headerName: 'Entry Date',
		width: 200,
		type: 'date',
		sortable: false,
		valueFormatter: ({ value }) => new Date(value),
	},
	{
		field: 'duration',
		headerName: 'Duration',
		width: 70,
		sortable: false,
		type: 'number',
		disableColumnMenu: true,
		valueFormatter: ({ value }) => (value === null ? 'null' : value),
	},
];

/**
 * @typedef extraColumns
 * @property {GridColumns[]} all
 * @property {GridColumns[]} valid
 *
 * @type {extraColumns}
 */
export const extraColumns = {
	all: [
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			disableColumnMenu: true,
			renderCell: ({ value }) => {
				if (value === 'valid')
					return (
						<div
							style={{
								fontWeight: 700,
								color: 'green',
							}}
						>
							Valid
						</div>
					);
				if (value === 'invalid')
					return (
						<div
							style={{
								fontWeight: 700,
								color: 'Red',
							}}
						>
							Invalid
						</div>
					);
				if (value === 'pending')
					return (
						<div
							style={{
								fontWeight: 700,
								color: '#CCCC00',
							}}
						>
							Pending
						</div>
					);
				if (value === 'disputed')
					return (
						<div
							style={{
								fontWeight: 800,
								color: 'black',
							}}
						>
							Disputed
						</div>
					);
				if (value === 'active')
					return (
						<div
							style={{
								fontWeight: 800,
								color: 'orange',
							}}
						>
							Active
						</div>
					);
			},
		},
	],

	valid: [
		{
			field: 'cost',
			headerName: 'Cost',
			width: 60,
			sortable: true,
			type: 'number',
			valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
		},
		{
			field: 'adjusted_cost',
			headerName: 'adjusted cost',
			width: 100,
			sortable: false,
			disableColumnMenu: true,
			editable: true,
			type: 'number',
			valueFormatter: ({ value }) => (value === null ? 'N / A' : value),
		},
		{
			field: 'remarks',
			headerName: 'Cost Remarks',
			width: 230,
			sortable: false,
			disableColumnMenu: true,
			type: 'number',
			renderCell: RenderCellExpand,
		},
	],
};

export const presentDate = new Date();

export const DataKeys = ['invalid', 'valid', 'pending', 'active', 'disputed'];

/**
 * @typedef Session
 * @property {number} id
 * @property {number} wp_order_id
 * @property {number} user_id
 * @property {string} user_name
 * @property {string} provider_id
 * @property {string} provider_name
 * @property {string} service_name
 * @property {string} requested
 * @property {number} duration
 * @property {1|0} user_marked
 * @property {1|0} provider_marked
 * @property {1|0} disputed
 * @property {1|0} admin_approved
 * @property {1|0} active
 * @property {number} cost
 *
 * @typedef {Session & { session_type: 'video' }} VideoSession
 *
 * @typedef {Session & { session_type: 'chat' }} ChatSession
 */

class Services {
	constructor() {
		throw new Error('Not allowed');
	}

	/**
	 *
	 * @param {Date} date
	 * @param {("auto" | "manual")} sortType
	 * @returns {Promise<{ video: VideoSession[]; chat: ChatSession[] }>}
	 */
	static async getData(date, sortType) {
		const [resVideo, resChat] = await Promise.all([
			makeRequestNew(`${uri}/billing/admin/sessions/video/${date.getMonth() + 1}/${date.getFullYear()}/${sortType}`),
			makeRequestNew(`${uri}/billing/admin/sessions/chat/${date.getMonth() + 1}/${date.getFullYear()}/${sortType}`),
		]);
		const [bodyVideo, bodyChat] = await Promise.all([resChat.json(), resVideo.json()]).catch(() => null);
		if (!resVideo.ok || !resChat.ok) throw new Error('Something Went wrong');
		return { video: bodyVideo, chat: bodyChat };
	}
	static async getInputData() {
		const response = makeRequest(`${uri}/billing/admin/sessions/provider-user/all`);
		return response;
	}
	static async addSession({ user_id, provider_id, service_id, type, duration, datetime }) {
		const response = await makeRequestNew(`${uri}/billing/admin/sessions`, 'POST', {
			user_id,
			provider_id,
			service_id,
			type,
			duration,
			datetime: new Date(datetime).getTime(),
		});
		const body = await response.json().catch(() => null);
		if (!response.ok) throw new Error(body?.message || 'Something went wrong');
		return body;
	}
	static async updateCost({ id, updatedCost, type }) {
		const response = await makeRequestNew(`${uri}/billing/admin/sessions/${type}/cost`, 'PATCH', { id, updatedCost });
		const body = await response.json().catch(() => null);
		if (!response.ok) throw new Error(body?.message || 'Somthing went wrong');
		return body;
	}
	static async approveSession({ id, type, status }) {
		const response = await makeRequestNew(`${uri}/billing/admin/sessions/${type}/approve/${status}/${id}`, 'PATCH', {});
		const body = await response.json().catch(() => null);
		if (!response.ok) throw new Error(body?.message || 'Something went wrong');
		return body;
	}
	static async rejectSession({ id, type, status }) {
		const response = await makeRequestNew(`${uri}/billing/admin/sessions/${type}/reject/${status}/${id}`, 'PATCH');
		const body = await response.json().catch((err) => null);
		if (!response.ok) throw new Error(!body.err || 'something went wrong');
		return body;
	}
	static async recalCost({ id, type }) {
		const response = await makeRequestNew(`${uri}/billing/admin/sessions/price/${type}/${id}`, 'GET');
		const body = await response.json().catch((e) => null);
		if (!response.ok) throw new Error(body?.err || 'Something went wrong');
		return body;
	}
}
export default Services;
