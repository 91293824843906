import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    list: [],
    analysis: {},
    loading: true
}

export const corpSlice=createSlice({
    name:"corpSlice",
    initialState,
    reducers:{
        GET_STATS_ANALYSIS:(state,action)=>{
            const analysis=action.payload
            state.analysis=analysis
        },
        GET_STATS_LIST:(state,action)=>{
            const list=action.payload;
            state.list=list
        },
        UPDATE_STATS_ITEM:(state,action)=>{
            const act=action.payload
            const objIndex = state.list.findIndex(obj => obj.user_id == act.user_id);
            state.list[objIndex].active = act.active;
        }
    }
})

export const {GET_STATS_ANALYSIS,GET_STATS_LIST,UPDATE_STATS_ITEM}=corpSlice.actions;

export default corpSlice.reducer;