import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';

function DialogComp({ open, handleClose, data }) {
	const i = 10;
	return (
		<Dialog open={open} handleClose={handleClose}>
			<DialogTitle>
				Provider Details
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			<div style={{ padding: '5px 50px' }}>
				<div className='pavatar'>
					<Avatar alt={data.name} src={data.picture} sx={{ width: 80, height: 80 }} />
				</div>
				<div className='textDetails'>
					<h3>{data.name}</h3>
					<span className='textrow'>
						<span>Email</span>
						<span>{data.email}</span>
					</span>
					<span className='textrow'>
						<span>Category</span>
						<span>{data.category}</span>
					</span>
					<span className='textrow'>
						<span>Years of Experience</span>
						<span>{data.yoe}</span>
					</span>
					<span className='textrow'>
						<span>Country</span>
						<span>{data.country}</span>
					</span>
					<span className='textrow'>
						<span>Gender</span>
						<span>{data?.gender?.toUpperCase()}</span>
					</span>
					<span className='textrow'>
						<span>Education</span>
						<span>{data?.other_details?.degree}</span>
					</span>
					<span className='textrow'>
						<span>Languages Known</span>
						<span>{data.languages}</span>
					</span>
					<span className='textrow'>
						<span>Specialisation</span>
						<span>
							{data.specialization?.split(',').map((el) => (
								<Chip label={el} variant='outlined' size='small' className='specialisationChip' />
							))}
						</span>
					</span>
					<span className='textrow'>
						<span>About</span>
						<span>{data.other_details?.profile_desc}</span>
					</span>
					<br />
					{/* <Button size="small" onClick={(e) => viewDetails(e)}>View full profile</Button> */}
				</div>
			</div>
		</Dialog>
	);
}

export default DialogComp;
