export const makeRequest = async (url, method = 'GET', data) => {
	/** @type {RequestInit} */
	const options = {
		method,
		headers: {
			'content-type': 'application/json',
			application: 'org.mantracare.admin-dash',
		},
		mode: 'cors',
		credentials: 'include',
		body: null,
	};

	if (data) {
		options.body = JSON.stringify(data);
	}

	const response = await fetch(url, options).then((response) => response.json());

	return response;
};

/**
 *
 * @param {string} url
 * @param {"GET" | "POST" | "DELETE" | "PATCH"} method
 * @param {object} data
 * @returns
 */

export const makeRequestNew = async (url, method = 'GET', data) => {
	const options = {
		method,
		headers: {
			'content-type': 'application/json',
			application: 'org.mantracare.admin-dash',
		},
		mode: 'cors',
		credentials: 'include',
		body: null,
	};

	if (data) {
		options.body = JSON.stringify(data);
	}

	return await fetch(url, options);
};

export const requestMedia = async (url, method = 'GET', form) => {
	const token = localStorage.getItem('token'),
		options = {
			method,
			body: null,
			headers: {},
		};
	if (token) {
		options.headers['x-auth-token'] = token;
	}

	if (form) {
		options.body = form;
	}

	const response = await fetch(url, options).then((response) => response.json());

	return response;
};
