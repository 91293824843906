import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { history } from './_helpers';
import { HomePage, LoginPage, PrivateRoute, SingleProvider } from './components';
import Billing from './components/Billing';
import Orders from './components/Orders';
import OrdersCorporate from './components/OrdersCorporate';
import Products from './components/Products';
import Sessions from './components/Sessions/Sessions';
import Users from './components/Users';
import User from './components/Users/User';
import ChatRooms from './components/chatRooms/ChatRooms';
import RoomChat from './components/chatRooms/RoomChat';
import Providers from './components/providerNew/Providers';
import { useAuth } from './hook/useAuth';
import './index.css';
import Payments from './components/payments/Index';
import Malicious from './components/maliciousChat/Index';

function App() {
	const loading = useAuth();

	useEffect(() => {
		console.log('rerender');
	}, [loading]);

	if (loading)
		return (
			<div className='fullScreenLoader'>
				<CircularProgress size={60} />
			</div>
		);

	return (
		<Switch history={history}>
			<Route path='/login' render={(props) => <LoginPage {...props} />} />
			<PrivateRoute exact path='/' component={HomePage} roles={['superuser']} />
			<PrivateRoute exact path='/providers' component={Providers} roles={['superuser']} />
			<PrivateRoute exact path='/provider/:id' component={SingleProvider} roles={['superuser']} />
			<PrivateRoute exact path='/providers/sessions' component={Sessions} roles={['superuser']} />
			<PrivateRoute exact path='/providers/billing' component={Billing} roles={['superuser']} />
			<PrivateRoute exact path='/providers/payments' component={Payments} roles={['superuser']} />
			<PrivateRoute exact path='/orders/b2b' component={OrdersCorporate} roles={['superuser']} />
			<PrivateRoute exact path='/orders/b2c' component={Orders} roles={['superuser']} />
			<PrivateRoute exact path='/products' component={Products} roles={['superuser']} />
			<PrivateRoute exact path='/users' component={Users} roles={['superuser']} />
			<PrivateRoute exact path='/users/:userID' component={User} roles={['superuser']} />
			<PrivateRoute exact path='/chat/rooms/:roomId' component={RoomChat} roles={['superuser']} />
			<PrivateRoute exact path='/chat/rooms' component={ChatRooms} roles={['superuser']} />
			<PrivateRoute exact path='/chat/malicious' component={Malicious} roles={['superuser']} />
			{/* <PrivateRoute exact path="/corporate/reports" component={CorporateHome} roles={['business']} /> */}
			<Route path='*' render={(props) => <>Not Found</>} />
		</Switch>
	);
}

export default App;
